import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth"; // Importa Firebase Auth

function Navbar() {
  const navigate = useNavigate();
  const auth = getAuth(); // Instancia de autenticación de Firebase

  const handleLogout = async () => {
    try {
      await signOut(auth); // Cierra sesión en Firebase
      navigate("/"); // Redirigir al login
      console.log("✅ Sesión cerrada correctamente");
    } catch (error) {
      console.error("❌ Error al cerrar sesión:", error);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Mi Plataforma Financiera
        </Typography>
        
        <Button color="inherit" onClick={() => navigate("/dashboard")}>
          Inicio
        </Button>
        <Button color="inherit" onClick={() => navigate("/tutoriales")}>
  TUTORIALES
</Button>

        <Button color="inherit" onClick={() => navigate("/configuracion")}>
          Configuración
        </Button>
        <Button color="inherit" onClick={handleLogout}>
          Cerrar Sesión
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
