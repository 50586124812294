import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  Alert,
  Divider
} from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { query, where, getDocs, onSnapshot } from "firebase/firestore";
import {
  doc,
  getDoc,
  addDoc,
  collection
} from "firebase/firestore";
import { db } from "../firebase/firebase.js";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const SolicitudRecompensas = () => {
  // Estados del formulario
  const [seleccion, setSeleccion] = useState("");
  const [detalle, setDetalle] = useState("");
  const [comprobante, setComprobante] = useState(null);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [loadingSolicitud, setLoadingSolicitud] = useState(false);
  const [solicitudEnviada, setSolicitudEnviada] = useState(false);

  // Estados de autenticación y datos del usuario
  const navigate = useNavigate();
  const auth = getAuth();
  const [usuario, setUsuario] = useState(null);
  const [datosUsuario, setDatosUsuario] = useState(null);
  const [aprobadasRecompensas, setAprobadasRecompensas] = useState([]);

  useEffect(() => {
    if (usuario) {
      const q = query(
        collection(db, "solicitudesAmpliacion"),
        where("uid", "==", usuario.uid),
        where("tipoSolicitud", "==", "recompensa")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const opcionesEnviadas = snapshot.docs.map((doc) => doc.data().opcion);
        console.log("Opciones enviadas:", opcionesEnviadas); // Debug: ver qué opciones se obtienen
        setAprobadasRecompensas(opcionesEnviadas);
      }, (error) => {
        console.error("Error al consultar las solicitudes de recompensa:", error);
      });
      return () => unsubscribe();
    }
  }, [usuario]);
  
  
  
  

  // Opciones de retos y recompensas
  const opcionesRecompensas = [
    { value: "instagram15", label: "📸 Instagram: Publica 1 historia → +15 días" },
    { value: "instagram30", label: "🔥 Instagram: 3 historias en días distintos → +30 días" },
    { value: "facebook15", label: "📘 Facebook: Publica un post con captura → +15 días" },
    { value: "facebook30", label: "👍 Facebook: Post con 200 likes o 25 compartidos → +30 días" },
    { value: "twitter15", label: "🐦 Twitter: Tuitea con captura → +15 días" },
    { value: "twitter30", label: "💬 Twitter: Tweet con 200 likes o 50 retweets → +30 días" },
    { value: "tiktok15", label: "🎥 TikTok: Publica un video → +15 días" },
    { value: "tiktok30", label: "🏆 TikTok: Video con 2,000 likes → +30 días" }
  ];

  // Verificar autenticación
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUsuario(firebaseUser);
      } else {
        navigate("/login");
      }
    });
    return unsubscribe;
  }, [navigate, auth]);

  // Cargar datos del usuario
  useEffect(() => {
    const fetchDatosUsuario = async () => {
      if (usuario) {
        try {
          const userDocRef = doc(db, "usuarios", usuario.uid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            setDatosUsuario(docSnap.data());
          } else {
            console.error("No se encontró el documento de usuario.");
          }
        } catch (error) {
          console.error("Error al cargar datos del usuario:", error);
        }
      }
    };
    fetchDatosUsuario();
  }, [usuario]);

  // Función para manejar el cambio del comprobante (imagen)
  const handleComprobanteChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setComprobante(e.target.files[0]);
    }
  };

  // Función para subir el comprobante a Storage y obtener su URL
  const uploadComprobante = async () => {
    if (!comprobante) return "";
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `recompensas/${usuario.uid}/${comprobante.name}_${Date.now()}`
    );
    try {
      const snapshot = await uploadBytes(storageRef, comprobante);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error al subir el comprobante:", error);
      throw error;
    }
  };

  // Función para enviar la solicitud de recompensa
  const handleEnviarSolicitud = async (e) => {
    e.preventDefault();
    setError("");
    setMensaje("");
    setLoadingSolicitud(true);

    // Verificar que el usuario tenga más de 30 días de suscripción activa
    if (datosUsuario && datosUsuario.creditos <= 30) {
      setError("❌ Promoción solo para cuentas con suscripción activa (más de 30 días).");
      setLoadingSolicitud(false);
      return;
    }
    if (!seleccion) {
      setError("❌ Seleccione una opción de reto/recompensa.");
      setLoadingSolicitud(false);
      return;
    }

    try {
      const comprobanteURL = await uploadComprobante();
      const solicitud = {
        uid: usuario.uid,
        idUserCustom: datosUsuario ? datosUsuario.id : null,
        opcion: seleccion, // Aquí se guarda el valor seleccionado (por ejemplo, "instagram15")
        detalle,
        comprobanteNombre: comprobante ? comprobante.name : "",
        comprobanteURL,
        fechaSolicitud: new Date().toISOString(),
        status: "pendiente",
        tipoSolicitud: "recompensa",
        creditosActuales: datosUsuario ? datosUsuario.creditos : 0
      };
      

      await addDoc(collection(db, "solicitudesAmpliacion"), solicitud);
      setMensaje("✅ Su solicitud de recompensa fue enviada. Espere la respuesta de los administradores.");
      setSolicitudEnviada(true);
    } catch (err) {
      console.error("Error al enviar la solicitud de recompensa:", err);
      setError("❌ Error al enviar la solicitud. Inténtelo nuevamente.");
    } finally {
      setLoadingSolicitud(false);
    }
  };

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={6} sx={{ p: 4, borderRadius: 3 }}>
        {/* Encabezado llamativo */}
        <Typography variant="h3" align="center" gutterBottom sx={{ color: "#FF5722", fontWeight: "bold" }}>
          ¡CONSIGUE MÁS DÍAS GRATIS! 🎉
        </Typography>
        <Typography variant="h6" align="center" gutterBottom sx={{ color: "#1976D2", mb: 2 }}>
          ¡Si tienes una suscripción activa, puedes ganar GRATIS más días cumpliendo nuestros retos!
        </Typography>

        {/* Bloque de instrucciones y retos */}
        <Paper elevation={3} sx={{ p: 3, mb: 3, backgroundColor: "#FFF3E0", borderRadius: 2 }}>
          <Typography variant="h5" align="center" sx={{ color: "#FF5722", fontWeight: "bold", mb: 2 }}>
            🔥 Retos y Recompensas por Red Social 🔥
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, color: "#424242" }}>
            <strong>📲 Instagram</strong>
            <br />
            ✅ Publica una historia (cuenta pública, por lo menos durante las próximas 48 horas) mencionándonos <strong>@finanzasfacilesmundial</strong> → <strong>+15 días</strong>
            <br />
            ✅ Si publicas <strong>3 historias en días distintos</strong> → <strong> +30 días</strong>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, color: "#424242" }}>
            <strong>📘 Facebook</strong>
            <br />
            ✅ Publica un post con una captura de nuestra plataforma y una breve opinión (no es necesario etiquetarnos) → <strong>+15 días</strong>
            <br />
            ✅ Si el post supera <strong>200 likes o 25 compartidos</strong> → <strong> +30 días</strong>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, color: "#424242" }}>
            <strong>🐦 Twitter (X)</strong>
            <br />
            ✅ Tuitea sobre nuestra plataforma con una captura y el link → <strong>+15 días</strong>
            <br />
            ✅ Si el tweet supera <strong>200 likes o 50 retweets</strong> → <strong> +30 días</strong>
          </Typography>
          <Typography variant="body1" sx={{ mb: 1, color: "#424242" }}>
            <strong>🎥 TikTok</strong>
            <br />
            ✅ Publica un video hablando de nuestra plataforma → <strong>+15 días</strong>
            <br />
            ✅ Si el video llega a <strong>2,000 likes</strong> → <strong> +30 días</strong>
          </Typography>
          <Typography variant="body1" align="center" sx={{ mt: 2, color: "#1976D2", fontWeight: "bold" }}>
            📌 ¡Puedes completar estos retos en todas las redes y ganar hasta <strong>6 meses gratis</strong>!
          </Typography>
        </Paper>

        {/* Formulario de solicitud de recompensa */}
        <form onSubmit={handleEnviarSolicitud}>
        <TextField
  select
  label="Selecciona tu reto/recompensa"
  value={seleccion}
  onChange={(e) => setSeleccion(e.target.value)}
  fullWidth
  required
  sx={{ mb: 2 }}
>
{opcionesRecompensas
  .filter((opcion) => 
    !aprobadasRecompensas.some((v) => 
      v && v.trim().toLowerCase() === opcion.value.trim().toLowerCase()
    )
  )
  .map((opcion) => (
    <MenuItem key={opcion.value} value={opcion.value}>
      {opcion.label}
    </MenuItem>
  ))}

</TextField>




          <TextField
            label="Detalle adicional (opcional)"
            value={detalle}
            onChange={(e) => setDetalle(e.target.value)}
            fullWidth
            multiline
            rows={3}
            sx={{ mb: 2 }}
          />

          <Button variant="contained" component="label" color="secondary" sx={{ mb: 2 }}>
            Adjuntar imagen (captura) 📸
            <input type="file" accept="image/*" hidden onChange={handleComprobanteChange} />
          </Button>
          {comprobante && (
            <Typography variant="body2" sx={{ mb: 2 }}>
              Archivo: {comprobante.name}
            </Typography>
          )}

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {mensaje && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {mensaje}
            </Alert>
          )}

          <Button type="submit" variant="contained" color="primary" fullWidth disabled={loadingSolicitud || solicitudEnviada}>
            {loadingSolicitud ? "Enviando solicitud..." : "Enviar solicitud de recompensa"}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default SolicitudRecompensas;
