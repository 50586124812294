// SuperAdminPanel.js

import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from "@mui/material";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../firebase/firebase.js";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PaymentIcon from "@mui/icons-material/Payment";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Define tu UID de superadministrador
const SUPERADMIN_UID = "bpd7VQqhTZQ9HK4xU5FUNInS05H3";

function SuperAdminPanel() {
  // Estados de autenticación
  const [authorized, setAuthorized] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);

  // Estados para solicitudes
  const [solicitudesPendientes, setSolicitudesPendientes] = useState([]);
  const [solicitudesAprobadas, setSolicitudesAprobadas] = useState([]);
  const [solicitudesRechazadas, setSolicitudesRechazadas] = useState([]);
  const [loadingSolicitudes, setLoadingSolicitudes] = useState(true);

  // Estado para indicar si el usuario (solicitud) ya tiene solicitud pendiente (para bloquear acciones en el usuario actual)
  const [solicitudPendiente, setSolicitudPendiente] = useState(false);

  // Estado para inputs de días (para cada solicitud pendiente)
  const [inputsDias, setInputsDias] = useState({}); // Ej.: { solicitudId: { agregar: "", quitar: "" } }

  // Estado para el buscador de solicitudes por ID (informativo)
  const [busqueda, setBusqueda] = useState("");
  const [usuarioBuscado, setUsuarioBuscado] = useState(null);

  // Estados para los inputs del formulario manual
const [manualAgregar, setManualAgregar] = useState("");
const [manualQuitar, setManualQuitar] = useState("");

// Estado de loading para la actualización manual de créditos
const [loadingActualizar, setLoadingActualizar] = useState(false);


  // Estados para modales e inputs adicionales
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openRechazoModal, setOpenRechazoModal] = useState(false);
  const [razonRechazo, setRazonRechazo] = useState("");
  const [solicitudEnProceso, setSolicitudEnProceso] = useState(null);
  const [openMensajeMasivoModal, setOpenMensajeMasivoModal] = useState(false);
  const [mensajeMasivo, setMensajeMasivo] = useState("");
  const [imagenMasiva, setImagenMasiva] = useState(null);
  const [botonTexto, setBotonTexto] = useState("");
  const [botonLink, setBotonLink] = useState("");
  const [loadingMensajeMasivo, setLoadingMensajeMasivo] = useState(false);
  const [comprobante, setComprobante] = useState(null);

  // Estados de loading para botones de operaciones
  const [loadingAprobar, setLoadingAprobar] = useState(false);
  const [loadingRechazar, setLoadingRechazar] = useState(false);
  const [loadingRecargar, setLoadingRecargar] = useState(false);

  // Otros estados
  const [mensajesSuper, setMensajesSuper] = useState([]);
  const [usuario, setUsuario] = useState(null);
  const [datosUsuario, setDatosUsuario] = useState(null); // Datos del documento "usuarios"
  const [manualRecarga, setManualRecarga] = useState(false);
  // Estado para el modal de pago con YAPE
  const [openModalYape, setOpenModalYape] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  // --- Verificar autenticación (solo superadmin) ---
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        if (firebaseUser.uid === SUPERADMIN_UID) {
          setAuthorized(true);
        } else {
          navigate("/dashboard");
        }
        setUsuario(firebaseUser);
      } else {
        navigate("/login");
      }
      setLoadingAuth(false);
    });
    return unsubscribe;
  }, [navigate, auth]);

  // --- Cargar datos del usuario desde "usuarios" ---
  useEffect(() => {
    const fetchDatosUsuario = async () => {
      if (usuario) {
        try {
          const userDocRef = doc(db, "usuarios", usuario.uid);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            setDatosUsuario({ ...docSnap.data(), uid: usuario.uid });
          } else {
            console.error("No se encontró el documento de usuario.");
          }
        } catch (error) {
          console.error("Error al cargar datos del usuario:", error);
        }
      }
    };
    fetchDatosUsuario();
  }, [usuario]);
  

  // --- Cargar solicitudes (pendientes, aprobadas, rechazadas) ---
  useEffect(() => {
    const fetchSolicitudes = async () => {
      try {
        // Pendientes
        const qPendientes = query(
          collection(db, "solicitudesAmpliacion"),
          where("status", "==", "pendiente")
        );
        const snapshotPendientes = await getDocs(qPendientes);
        let pendientes = [];
        snapshotPendientes.forEach((docSnapshot) => {
          pendientes.push({ id: docSnapshot.id, ...docSnapshot.data() });
        });
        if (busqueda) {
          pendientes = pendientes.filter((sol) =>
            sol.idUserCustom?.toString().includes(busqueda)
          );
        }
        setSolicitudesPendientes(pendientes);

        // Aprobadas
        const qAprobadas = query(
          collection(db, "solicitudesAmpliacion"),
          where("status", "==", "aprobado")
        );
        const snapshotAprobadas = await getDocs(qAprobadas);
        const aprobadas = [];
        snapshotAprobadas.forEach((docSnapshot) => {
          aprobadas.push({ id: docSnapshot.id, ...docSnapshot.data() });
        });
        setSolicitudesAprobadas(aprobadas);

        // Rechazadas
        const qRechazadas = query(
          collection(db, "solicitudesAmpliacion"),
          where("status", "==", "rechazado")
        );
        const snapshotRechazadas = await getDocs(qRechazadas);
        const rechazadas = [];
        snapshotRechazadas.forEach((docSnapshot) => {
          rechazadas.push({ id: docSnapshot.id, ...docSnapshot.data() });
        });
        setSolicitudesRechazadas(rechazadas);
      } catch (error) {
        console.error("Error al obtener solicitudes:", error);
      } finally {
        setLoadingSolicitudes(false);
      }
    };

    fetchSolicitudes();
  }, [busqueda]);

  // --- Función para manejar cambios en inputs de días ---
  const handleInputDiasChange = (solicitudId, field, value) => {
    setInputsDias((prev) => ({
      ...prev,
      [solicitudId]: {
        ...prev[solicitudId],
        [field]: value
      }
    }));
  };

  // --- Función para aprobar una solicitud ---
  const handleAprobarSolicitud = async (solicitud) => {
    const input = inputsDias[solicitud.id] || { agregar: "", quitar: "" };
    if (input.agregar === "" && input.quitar === "") {
      alert("Ingrese al menos un valor en Agregar o Quitar días.");
      return;
    }
    setLoadingAprobar(true);
    try {
      // Referencia al documento del usuario (usando el UID correcto)
      const userDocRef = doc(db, "usuarios", solicitud.uid);
      const docSnap = await getDoc(userDocRef);
      let currentCreditos = 0;
      if (docSnap.exists()) {
        currentCreditos = docSnap.data().creditos || 0;
      }
      const agregar = Number(input.agregar) || 0;
      const quitar = Number(input.quitar) || 0;
      const nuevosCreditos = currentCreditos + agregar - quitar;
      const nuevaFecha = new Date().toISOString();
      // Actualizamos el documento del usuario en Firestore
      await updateDoc(userDocRef, {
        creditos: nuevosCreditos,
        lastUpdate: nuevaFecha
      });
      
      // Actualizamos el documento de la solicitud a "aprobado"
      const solicitudRef = doc(db, "solicitudesAmpliacion", solicitud.id);
await updateDoc(solicitudRef, {
  status: "aprobado",
  superadminMessage: `Aprobado: Se han ajustado los días. (Agregados: ${agregar}, Quitados: ${quitar})`,
  creditosActuales: nuevosCreditos
});

      
      // Agregamos el mensaje al campo "messages" del usuario
      await updateDoc(userDocRef, {
        messages: arrayUnion({
          type: "aprobacion",
          text: `Su solicitud fue aprobada. Se han ajustado los días. (Agregados: ${agregar}, Quitados: ${quitar}).`,
          date: new Date().toISOString()
        })
      });
      
      alert("Solicitud aprobada y días actualizados exitosamente.");
      setSolicitudesPendientes((prev) => prev.filter((s) => s.id !== solicitud.id));
      setSolicitudesAprobadas((prev) => [
        ...prev,
        { ...solicitud, status: "aprobado", superadminMessage: `Aprobado: Se han ajustado los días. (Agregados: ${agregar}, Quitados: ${quitar})` }
      ]);
    } catch (error) {
      console.error("Error al aprobar la solicitud:", error);
      alert("Error al aprobar la solicitud.");
    } finally {
      setLoadingAprobar(false);
    }
  };
  
  // --- Función para abrir el modal de rechazo ---
  const handleAbrirRechazo = (solicitud) => {
    setSolicitudEnProceso(solicitud);
    setOpenRechazoModal(true);
  };

  // --- Función para rechazar una solicitud ---
  const handleRechazarSolicitud = async () => {
    if (!razonRechazo) {
      alert("Ingrese la razón del rechazo.");
      return;
    }
    setLoadingRechazar(true);
    try {
        const solicitudRefRechazo = doc(db, "solicitudesAmpliacion", solicitudEnProceso.id);
        await updateDoc(solicitudRefRechazo, {
          status: "rechazado",
          superadminMessage: "Rechazada: " + razonRechazo
        });
        
        // Actualizamos el documento del usuario para enviarle un mensaje de rechazo
        const userDocRef = doc(db, "usuarios", solicitudEnProceso.uid);
        await updateDoc(userDocRef, {
          messages: arrayUnion({
            type: "rechazo",
            text: `Su solicitud fue rechazada: ${razonRechazo}. Para mayor información o si cree que es un error, comuníquese al WhatsApp: +51 906 115 152.`,
            date: new Date().toISOString()
          })
        });
        
        alert("Solicitud rechazada.");
        
      setSolicitudesPendientes((prev) =>
        prev.filter((s) => s.id !== solicitudEnProceso.id)
      );
      setSolicitudesRechazadas((prev) => [
        ...prev,
        { ...solicitudEnProceso, status: "rechazado", superadminMessage: "Rechazada: " + razonRechazo }
      ]);
      setOpenRechazoModal(false);
      setRazonRechazo("");
    } catch (error) {
      console.error("Error al rechazar la solicitud:", error);
      alert("Error al rechazar la solicitud.");
    } finally {
      setLoadingRechazar(false);
    }
  };

  // --- Función para reactivar una solicitud aprobada (volver a pendiente) ---
  const handleReactivarSolicitud = async (solicitud) => {
    try {
      const solicitudDocRef = doc(db, "solicitudesAmpliacion", solicitud.id);
      await updateDoc(solicitudDocRef, { status: "pendiente", superadminMessage: "" });
      setSolicitudesAprobadas((prev) => prev.filter((s) => s.id !== solicitud.id));
      setSolicitudesPendientes((prev) => [...prev, { ...solicitud, status: "pendiente" }]);
    } catch (error) {
      console.error("Error al reactivar la solicitud:", error);
      alert("Error al reactivar la solicitud.");
    }
  };

  const handleEliminarSolicitud = async (solicitud) => {
    try {
      // Borra el documento de la solicitud en la colección "solicitudesAmpliacion"
      await deleteDoc(doc(db, "solicitudesAmpliacion", solicitud.id));
      // Actualiza el estado de solicitudes aprobadas y rechazadas
      setSolicitudesAprobadas((prev) => prev.filter((s) => s.id !== solicitud.id));
      setSolicitudesRechazadas((prev) => prev.filter((s) => s.id !== solicitud.id));
      alert("Solicitud eliminada permanentemente.");
    } catch (error) {
      console.error("Error al eliminar la solicitud:", error);
      alert("Error al eliminar la solicitud.");
    }
  };
  

  // --- Función para recargar el estado (botón "Recargar estado") ---
  const verificarSolicitud = async () => {
    if (!usuario) {
      navigate("/login");
      return;
    }
    try {
      const docRef = doc(db, "solicitudesAmpliacion", usuario.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.status === "pendiente") {
          if (manualRecarga) {
            alert("No se detectaron cambios en el estado de la solicitud.");
          }
          setSolicitudPendiente(true);
        } else {
          setSolicitudPendiente(false);
        }
      } else {
        setSolicitudPendiente(false);
      }
    } catch (err) {
      console.error("Error al verificar la solicitud:", err);
    } finally {
      setLoadingRecargar(false);
      setManualRecarga(false);
    }
  };

  const handleRecargarEstado = async () => {
    setManualRecarga(true);
    setLoadingRecargar(true);
    await verificarSolicitud();
  };

  // --- Función para subir el comprobante a Storage y obtener su URL ---
  const uploadComprobante = async () => {
    if (!comprobante) return "";
    const storage = getStorage();
    const storageRef = ref(storage, `comprobantes/${usuario.uid}/${comprobante.name}_${Date.now()}`);
    try {
      const snapshot = await uploadBytes(storageRef, comprobante);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error al subir el comprobante:", error);
      throw error;
    }
  };

  // --- Función para enviar mensaje masivo ---
  const handleEnviarMensajeMasivo = async () => {
    if (!mensajeMasivo) {
      alert("Ingrese el mensaje masivo.");
      return;
    }
    setLoadingMensajeMasivo(true);
    try {
      let imagenURL = "";
      if (imagenMasiva) {
        const storage = getStorage();
        const storageRef = ref(storage, `mensajesMasivos/${Date.now()}_${imagenMasiva.name}`);
        const snapshot = await uploadBytes(storageRef, imagenMasiva);
        imagenURL = await getDownloadURL(snapshot.ref);
      }
      await setDoc(doc(db, "massMessages", "lastMassMessage"), {
        mensaje: mensajeMasivo,
        imagenURL,
        botonTexto: botonTexto || "",
        botonLink: botonLink || "",
        fecha: new Date().toISOString()
      });
      alert("Mensaje masivo enviado exitosamente.");
      setMensajeMasivo("");
      setImagenMasiva(null);
      setBotonTexto("");
      setBotonLink("");
      setOpenMensajeMasivoModal(false);
    } catch (error) {
      console.error("Error al enviar el mensaje masivo:", error);
      alert("Error al enviar el mensaje masivo.");
    } finally {
      setLoadingMensajeMasivo(false);
    }
  };

  const handleBuscarUsuario = async () => {
    if (!busqueda) {
      alert("Por favor, ingrese un ID para buscar.");
      return;
    }
    try {
      // Convertimos la búsqueda a número, asumiendo que el ID es un número de 7 dígitos.
      const idBuscado = Number(busqueda);
      // Consulta la colección "usuarios" donde el campo "id" es igual al valor buscado.
      const q = query(collection(db, "usuarios"), where("id", "==", idBuscado));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        alert("No se encontró ningún usuario con ese ID.");
        setUsuarioBuscado(null);
      } else {
        let usuarioEncontrado = null;
        querySnapshot.forEach((doc) => {
          // Construimos el objeto de usuario:
          // Guardamos "id" (el documento buscado) y usamos el campo "uid" del documento si existe.
          usuarioEncontrado = { 
            id: doc.id, 
            uid: doc.data().uid ? doc.data().uid : doc.id, 
            ...doc.data() 
          };
        });
        console.log("Usuario buscado para actualizar:", usuarioEncontrado);
        setUsuarioBuscado(usuarioEncontrado);
      }
    } catch (error) {
      console.error("Error al buscar usuario:", error);
      alert("Hubo un error al buscar el usuario.");
    }
  };
  
  
  const handleActualizarCreditos = async () => {
    setLoadingActualizar(true);
    try {
      // Usamos el UID real del usuario: el que viene en usuarioBuscado.uid
      const uidAActualizar = usuarioBuscado.uid;
      console.log("Actualizando documento con UID:", uidAActualizar);
      const userDocRef = doc(db, "usuarios", uidAActualizar);
      const docSnapBefore = await getDoc(userDocRef);
      console.log("Antes de actualizar:", docSnapBefore.data());
      let currentCreditos = 0;
      if (docSnapBefore.exists()) {
        currentCreditos = docSnapBefore.data().creditos || 0;
      }
      const agregar = Number(manualAgregar) || 0;
      const quitar = Number(manualQuitar) || 0;
      // Calcula los nuevos créditos (pueden ser negativos si se reduce demasiado)
      const nuevosCreditos = currentCreditos + agregar - quitar;
      const nuevaFecha = new Date().toISOString();
      // Actualizamos el documento usando setDoc con merge:true
      await setDoc(userDocRef, {
        creditos: nuevosCreditos,
        lastUpdate: nuevaFecha
      }, { merge: true });
      const docSnapAfter = await getDoc(userDocRef);
      console.log("Después de actualizar:", docSnapAfter.data());
      alert("Créditos actualizados exitosamente.");
      // Actualizamos el estado local
      setUsuarioBuscado({
        ...usuarioBuscado,
        creditos: nuevosCreditos
      });
    } catch (error) {
      console.error("Error al actualizar créditos:", error);
      alert("Error al actualizar créditos.");
    } finally {
      setLoadingActualizar(false);
    }
  };
  
  

  // --- Renderizado Condicional para autenticación ---
  if (loadingAuth) {
    return (
      <Container component="main" maxWidth="md">
        <Typography variant="h5" align="center">Cargando...</Typography>
      </Container>
    );
  }
  if (!authorized) {
    return null;
  }

  return (
    <>
      {/* Buscador de solicitudes por ID */}
      <Container sx={{ mt: 4, display: "flex", gap: 2 }}>
  <TextField
    label="Buscar por ID"
    variant="outlined"
    fullWidth
    value={busqueda}
    onChange={(e) => setBusqueda(e.target.value)}
  />
  <Button variant="contained" color="primary" onClick={handleBuscarUsuario}>
    Buscar Usuario
  </Button>
</Container>

{usuarioBuscado && (
  <Paper sx={{ mt: 4, p: 2 }}>
    <Typography variant="h6" gutterBottom>
      Usuario Encontrado
    </Typography>
    <Typography variant="body1">
      <strong>Nombre:</strong> {usuarioBuscado.nombre}
    </Typography>
    <Typography variant="body1">
      <strong>ID:</strong> {usuarioBuscado.idUserCustom ? usuarioBuscado.idUserCustom : usuarioBuscado.id}
    </Typography>
    <Typography variant="body1" sx={{ mb: 2 }}>
      <strong>Créditos actuales:</strong> {usuarioBuscado.creditos}
    </Typography>
    <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
      <TextField
        label="Agregar días"
        type="number"
        value={manualAgregar}
        onChange={(e) => setManualAgregar(e.target.value)}
        fullWidth
      />
      <TextField
        label="Quitar días"
        type="number"
        value={manualQuitar}
        onChange={(e) => setManualQuitar(e.target.value)}
        fullWidth
      />
    </Box>
    <Button
      variant="contained"
      color="primary"
      onClick={handleActualizarCreditos}
      disabled={loadingActualizar}
    >
      {loadingActualizar ? "Actualizando..." : "Actualizar Créditos"}
    </Button>
  </Paper>
)}


      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        {/* Sección de información del superadministrador */}
        <Paper elevation={6} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Panel de Superadministrador
          </Typography>
          <Typography variant="body1">
            Bienvenido, superadministrador.
          </Typography>
        </Paper>

        {/* Sección de solicitudes pendientes */}
        <Paper elevation={6} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Solicitudes de Extensión Pendientes
          </Typography>
          {loadingSolicitudes ? (
            <Typography variant="body1">Cargando solicitudes...</Typography>
          ) : solicitudesPendientes.length === 0 ? (
            <Alert severity="info">No hay solicitudes pendientes.</Alert>
          ) : (
            <Grid container spacing={2}>
              {solicitudesPendientes.map((sol) => (
                <Grid item xs={12} key={sol.id}>
                  <Card>
                  <CardContent>
  <Typography variant="subtitle1">
    Usuario: {sol.nombre ? sol.nombre : "Sin nombre"}
  </Typography>
  <Typography variant="body2">
    ID Informativo: {sol.idUserCustom ? sol.idUserCustom : "No definido"}
  </Typography>
  <Typography variant="body2">
    UID: {sol.uid ? sol.uid : "No definido"}
  </Typography>
  <Typography variant="body2">
    Tiempo solicitado: {sol.suscripcion}
  </Typography>
  <Typography variant="body2">
    Días restantes: {sol.creditosActuales !== undefined ? sol.creditosActuales : "N/A"}
  </Typography>

  {/* Si es una solicitud de recompensa, mostramos la opción seleccionada y los días actuales */}
  {sol.tipoSolicitud === "recompensa" && (
    <>
      <Typography variant="body2" sx={{ mt: 1 }}>
        <strong>Opción:</strong> {sol.opcion}
      </Typography>
      <Typography variant="body2">
        <strong>Días actuales:</strong> {sol.creditosActuales !== undefined ? sol.creditosActuales : "N/A"}
      </Typography>
    </>
  )}

  {sol.comprobanteURL ? (
    <Box
      component="img"
      src={sol.comprobanteURL}
      alt="Comprobante"
      sx={{ width: 100, height: 100, mt: 1, cursor: "pointer" }}
      onClick={() => {
        setSelectedImage(sol.comprobanteURL);
        setOpenImageModal(true);
      }}
    />
  ) : sol.comprobanteNombre ? (
    <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
      Comprobante: {sol.comprobanteNombre}
    </Typography>
  ) : (
    <Typography variant="caption">Sin comprobante adjunto.</Typography>
  )}
</CardContent>


                    <CardActions>
                      <TextField
                        label="Agregar días"
                        type="number"
                        size="small"
                        sx={{ width: 120 }}
                        value={inputsDias[sol.id]?.agregar || ""}
                        onChange={(e) =>
                          handleInputDiasChange(sol.id, "agregar", e.target.value)
                        }
                      />
                      <TextField
                        label="Quitar días"
                        type="number"
                        size="small"
                        sx={{ width: 120 }}
                        value={inputsDias[sol.id]?.quitar || ""}
                        onChange={(e) =>
                          handleInputDiasChange(sol.id, "quitar", e.target.value)
                        }
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAprobarSolicitud(sol)}
                        disabled={loadingAprobar}
                      >
                        {loadingAprobar ? "Procesando..." : "Aprobar"}
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleAbrirRechazo(sol)}
                        disabled={loadingRechazar}
                      >
                        {loadingRechazar ? "Procesando..." : "Rechazar"}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {solicitudesPendientes.length > 0 && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Button
                variant="outlined"
                onClick={handleRecargarEstado}
                disabled={loadingRecargar}
              >
                {loadingRecargar ? "Recargando..." : "Recargar estado"}
              </Button>
            </Box>
          )}
        </Paper>

        {/* Sección de solicitudes aprobadas */}
        <Paper elevation={6} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Solicitudes Aprobadas
          </Typography>
          {loadingSolicitudes ? (
            <Typography variant="body1">Cargando solicitudes...</Typography>
          ) : solicitudesAprobadas.length === 0 ? (
            <Alert severity="info">No hay solicitudes aprobadas.</Alert>
          ) : (
            <Grid container spacing={2}>
              {solicitudesAprobadas.map((sol) => (
                <Grid item xs={12} key={sol.id}>
                  <Card>
                  <CardContent>
  <Typography variant="subtitle1">
    Usuario: {sol.nombre ? sol.nombre : "Sin nombre"}
  </Typography>
  <Typography variant="body2">
    ID Informativo: {sol.idUserCustom ? sol.idUserCustom : "No definido"}
  </Typography>
  <Typography variant="body2">
    UID: {sol.uid ? sol.uid : "No definido"}
  </Typography>
  <Typography variant="body2">
    Tiempo solicitado: {sol.suscripcion}
  </Typography>
  <Typography variant="body2">
  Días restantes: {sol.creditosActuales !== undefined ? sol.creditosActuales : "N/A"}
</Typography>

  {sol.comprobanteURL ? (
    <Box
      component="img"
      src={sol.comprobanteURL}
      alt="Comprobante"
      sx={{ width: 100, height: 100, mt: 1, cursor: "pointer" }}
      onClick={() => {
        setSelectedImage(sol.comprobanteURL);
        setOpenImageModal(true);
      }}
    />
  ) : sol.comprobanteNombre ? (
    <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
      Comprobante: {sol.comprobanteNombre}
    </Typography>
  ) : (
    <Typography variant="caption">Sin comprobante adjunto.</Typography>
  )}
</CardContent>

                    <CardActions>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleReactivarSolicitud(sol)}
                      >
                        Reactivar Solicitud
                      </Button>
                      <IconButton
    color="error"
    onClick={() => handleEliminarSolicitud(sol)}
    sx={{ ml: "auto" }}
  >
    <CloseIcon />
  </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>

        {/* Sección de solicitudes rechazadas */}
        <Paper elevation={6} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Solicitudes Rechazadas
          </Typography>
          {loadingSolicitudes ? (
            <Typography variant="body1">Cargando solicitudes...</Typography>
          ) : solicitudesRechazadas.length === 0 ? (
            <Alert severity="info">No hay solicitudes rechazadas.</Alert>
          ) : (
            <Grid container spacing={2}>
              {solicitudesRechazadas.map((sol) => (
                <Grid item xs={12} key={sol.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">
                        Usuario: {sol.nombre ? sol.nombre : sol.uid}
                      </Typography>
                      <Typography variant="body2">
                        ID: {sol.idUserCustom ? sol.idUserCustom : sol.uid}
                      </Typography>
                      <Typography variant="body2">
                        Tiempo solicitado: {sol.suscripcion}
                      </Typography>
                      <Typography variant="body2" color="red">
                        {sol.superadminMessage}
                      </Typography>
                      {sol.comprobanteURL ? (
                        <Box
                          component="img"
                          src={sol.comprobanteURL}
                          alt="Comprobante"
                          sx={{ width: 100, height: 100, mt: 1, cursor: "pointer" }}
                          onClick={() => {
                            setSelectedImage(sol.comprobanteURL);
                            setOpenImageModal(true);
                          }}
                        />
                      ) : sol.comprobanteNombre ? (
                        <Typography variant="caption" sx={{ mt: 1, display: "block" }}>
                          Comprobante: {sol.comprobanteNombre}
                        </Typography>
                      ) : (
                        <Typography variant="caption">Sin comprobante adjunto.</Typography>
                      )}
                    </CardContent>
                    <CardActions>
              <IconButton
                color="error"
                onClick={() => handleEliminarSolicitud(sol)}
                sx={{ ml: "auto" }}
              >
                <CloseIcon />
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      ))}
            </Grid>
          )}
        </Paper>

        {/* Sección de mensaje masivo */}
        <Paper elevation={6} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Mensaje Masivo
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenMensajeMasivoModal(true)}
          >
            Enviar Mensaje Masivo
          </Button>
        </Paper>
      </Container>



      {/* Modal para visualizar imagen completa */}
      <Modal open={openImageModal} onClose={() => setOpenImageModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={() => setOpenImageModal(false)}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <Box
              component="img"
              src={selectedImage}
              alt="Imagen completa"
              sx={{ width: "100%", maxWidth: 500 }}
            />
          )}
        </Box>
      </Modal>

      {/* Modal para el QR de YAPE */}
      <Dialog open={openModalYape} onClose={() => setOpenModalYape(false)}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Pago con YAPE
          <IconButton
            aria-label="close"
            onClick={() => setOpenModalYape(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ textAlign: "center" }}>
          <Typography variant="body1" gutterBottom>
            📲 Realiza tu pago fácilmente
          </Typography>
          <Typography variant="body1" gutterBottom>
            🔹 Yapea o haz un Plin al número 906 115 152
          </Typography>
          <Typography variant="body1" gutterBottom>
            🔹 A nombre de: Gean Carlo Miranda
          </Typography>
          <Typography variant="body1" gutterBottom>
            🔹 También puedes escanear el código QR de abajo 📸
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
            📌 Recuerda: Guarda una captura de pantalla de tu pago para enviarla en el formulario y activar tu acceso.
          </Typography>
          <Box
            component="img"
            src="/images/yapeQR.png"
            alt="Código QR de YAPE"
            sx={{ width: "100%", maxWidth: 300, mx: "auto", borderRadius: 2, boxShadow: 3 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalYape(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para Rechazar Solicitud */}
      <Modal open={openRechazoModal} onClose={() => setOpenRechazoModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Ingrese la razón del rechazo
          </Typography>
          <TextField
            label="Razón"
            fullWidth
            multiline
            rows={3}
            value={razonRechazo}
            onChange={(e) => setRazonRechazo(e.target.value)}
          />
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Button
              variant="contained"
              color="error"
              onClick={handleRechazarSolicitud}
              disabled={loadingRechazar}
            >
              {loadingRechazar ? "Procesando..." : "Enviar Rechazo"}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Modal para Mensaje Masivo */}
      <Dialog open={openMensajeMasivoModal} onClose={() => setOpenMensajeMasivoModal(false)} fullWidth>
        <DialogTitle>
          Enviar Mensaje Masivo
          <IconButton
            aria-label="close"
            onClick={() => setOpenMensajeMasivoModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Mensaje"
            fullWidth
            multiline
            rows={3}
            value={mensajeMasivo}
            onChange={(e) => setMensajeMasivo(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" component="label" color="secondary" sx={{ mb: 2 }}>
            Adjuntar imagen
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setImagenMasiva(e.target.files[0]);
                }
              }}
            />
          </Button>
          {imagenMasiva && (
            <Typography variant="body2" sx={{ mb: 2 }}>
              Archivo: {imagenMasiva.name}
            </Typography>
          )}
          <TextField
            label="Texto del botón (opcional)"
            fullWidth
            value={botonTexto}
            onChange={(e) => setBotonTexto(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Link del botón (opcional)"
            fullWidth
            value={botonLink}
            onChange={(e) => setBotonLink(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEnviarMensajeMasivo}
            disabled={loadingMensajeMasivo}
          >
            {loadingMensajeMasivo ? "Enviando..." : "Enviar Mensaje Masivo"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SuperAdminPanel;
