import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  Alert,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar
} from "recharts";
import { obtenerConceptos } from "../firebase/firebaseService.js";
import "jspdf-autotable";
import html2canvas from "html2canvas";

// Función para convertir fecha a formato "YYYY-MM-DD"
function normalizeDate(dateInput) {
  if (!dateInput) return "";
  const d = parseLocalDate(dateInput);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// Función para formatear la fecha en PDF a "DD/MM/YYYY"
function formatDatePDF(dateInput) {
  if (!dateInput) return "";
  const d = parseLocalDate(dateInput);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}

// Función para calcular los ticks del eje X (máximo 6 ticks)
function getXTicks(data) {
  const n = data.length;
  if (n <= 6) return data.map(item => item.fecha);
  const ticks = [];
  const step = (n - 1) / (6 - 1);
  for (let i = 0; i < 6; i++) {
    const index = Math.round(i * step);
    ticks.push(data[index].fecha);
  }
  return ticks;
}

// Función para convertir una cadena de fecha en objeto Date en hora local
function parseLocalDate(dateInput) {
  if (!dateInput) return new Date();
  const datePart = dateInput.split("T")[0];
  const [year, month, day] = datePart.split("-");
  return new Date(Number(year), Number(month) - 1, Number(day));
}

function ReporteGenerado() {
  const location = useLocation();
  const navigate = useNavigate();
  const { fechaDesde, fechaHasta, nivelReporte, nombreTabla } = location.state || {};
  const [allTransactions, setAllTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConceptos = async () => {
      try {
        const conceptosFirestore = await obtenerConceptos(nombreTabla);
        setAllTransactions(conceptosFirestore);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener conceptos de Firestore:", error);
        setAllTransactions([]);
        setLoading(false);
      }
    };
    if (nombreTabla !== "default") {
      fetchConceptos();
    }
  }, [nombreTabla]);

  const filteredTransactions = allTransactions
    .filter((tx) => {
      const txDate = normalizeDate(tx.fecha);
      return txDate >= fechaDesde && txDate <= fechaHasta;
    })
    .sort((a, b) => new Date(parseLocalDate(a.fecha)) - new Date(parseLocalDate(b.fecha)));

  // Calcular balance final
  const balance = filteredTransactions.reduce((acc, tx) => {
    return tx.tipo === "Ingreso" ? acc + Number(tx.monto) : acc - Number(tx.monto);
  }, 0);

  // Datos para gráficos de Nivel 2
  const groupByCategory = (transactions) => {
    const groups = {};
    transactions.forEach((tx) => {
      const cat = tx.categoria || "Sin categoría";
      if (!groups[cat]) groups[cat] = 0;
      groups[cat] += tx.tipo === "Ingreso" ? Number(tx.monto) : -Number(tx.monto);
    });
    return Object.entries(groups)
      .filter(([key, value]) => value < 0)
      .map(([key, value]) => ({ category: key, total: Math.abs(value) }));
  };

  const pieData = groupByCategory(filteredTransactions);
  const barData = [...pieData].sort((a, b) => b.total - a.total).slice(0, 5);

  const calculateAccumulatedBalance = (transactions) => {
    const sorted = [...transactions].sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
    let acc = 0;
    return sorted.map((tx) => {
      acc += tx.tipo === "Ingreso" ? Number(tx.monto) : -Number(tx.monto);
      return { fecha: normalizeDate(tx.fecha), balance: acc };
    });
  };

  const lineData = calculateAccumulatedBalance(filteredTransactions);

  
  // Datos para el gráfico comparativo (Ingresos vs Egresos)
  const comparativeDataObj = filteredTransactions.reduce((acc, tx) => {
    const date = normalizeDate(tx.fecha);
    if (!acc[date]) {
      acc[date] = { fecha: date, ingresos: 0, egresos: 0 };
    }
    if (tx.tipo === "Ingreso") {
      acc[date].ingresos += Number(tx.monto);
    } else {
      acc[date].egresos += Number(tx.monto);
    }
    return acc;
  }, {});
  const comparativeData = Object.values(comparativeDataObj).sort((a, b) => new Date(a.fecha) - new Date(b.fecha));



  // Datos para Nivel 3 (Estado de Resultados)
  const ingresos = filteredTransactions.reduce((acc, tx) => tx.tipo === "Ingreso" ? acc + Number(tx.monto) : acc, 0);
  const otrosIngresos = 0;
  const cogs = filteredTransactions.reduce((acc, tx) => tx.tipo === "Egreso" ? acc + Number(tx.monto) : acc, 0);
  const gastoOperativo = 0;
  const utilidadNeta = ingresos + otrosIngresos - (cogs + gastoOperativo);


  
// Calcular el número de días del intervalo y el ingreso promedio diario
const dias = (new Date(fechaHasta) - new Date(fechaDesde)) / (1000 * 60 * 60 * 24) + 1;
const ingresoPromedio = ingresos / dias;

// Calcular el total de egresos y el gasto promedio diario
const totalEgresos = filteredTransactions
  .filter(tx => tx.tipo === "Egreso")
  .reduce((acc, tx) => acc + Number(tx.monto), 0);
const gastoPromedio = totalEgresos / dias;

// Calcular la transacción de mayor ingreso (tipo "Ingreso")
const maxIncomeTx = filteredTransactions.reduce((max, tx) => {
  if (tx.tipo === "Ingreso") {
    return !max || Number(tx.monto) > Number(max.monto) ? tx : max;
  }
  return max;
}, null);

// Obtener la categoría con mayor gasto (usando barData, que ya ordenamos descendente)
const topExpenseCategory = barData[0] ? barData[0].category : 'N/A';
const topExpenseCategoryAmount = barData[0] ? barData[0].total : 0;

// Calcular la subcategoría más usada, si la propiedad 'subcategoria' existe en las transacciones
const subcategoriaCounts = {};
filteredTransactions.forEach((tx) => {
  if (tx.subcategoria) {
    subcategoriaCounts[tx.subcategoria] = (subcategoriaCounts[tx.subcategoria] || 0) + 1;
  }
});
const topSubcategoria = Object.keys(subcategoriaCounts).reduce((maxKey, key) => {
  return (subcategoriaCounts[key] > (subcategoriaCounts[maxKey] || 0)) ? key : maxKey;
}, "");


// Encontrar la transacción con mayor gasto (tipo "Egreso")
const maxExpenseTx = filteredTransactions.reduce((max, tx) => {
  if (tx.tipo === "Egreso") {
    return !max || Number(tx.monto) > Number(max.monto) ? tx : max;
  }
  return max;
}, null);


  // Títulos y descripciones
  let tituloDocumento = "";
  let descripcionDocumento = "";
  if (nivelReporte === "1") {
    tituloDocumento = "REPORTE FINANCIERO";
    descripcionDocumento = `Reporte financiero del ${formatDatePDF(fechaDesde)} al ${formatDatePDF(fechaHasta)}.`;
  } else if (nivelReporte === "2") {
    tituloDocumento = "INFORMACIÓN RELEVANTE";
    descripcionDocumento = `¡El futuro de tus finanzas está en tus manos! Con conocimiento y planificación, puedes lograr tus sueños y vivir con tranquilidad.`;
  } else if (nivelReporte === "3") {
    tituloDocumento = "ESTADO DE RESULTADOS";
    descripcionDocumento = `Cuadro resumen del desempeño financiero para el período seleccionado.`;
  } else {
    tituloDocumento = "REPORTE FINANCIERO";
    descripcionDocumento = `Reporte financiero del período seleccionado.`;
  }

  // Colores para el PieChart
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AA336A", "#33AA99"];

  // Función para descargar el PDF
  const descargarPDF = () => {
    console.log("Se ha presionado el botón de descargar PDF");
    const doc = new jsPDF("p", "mm", "a4");

    // Encabezado
    doc.setTextColor(33, 150, 243);
    doc.setFontSize(18);
    doc.text(tituloDocumento, 14, 20);

    // Descripción
    doc.setTextColor(66, 66, 66);
    doc.setFontSize(12);
    const maxWidth = 180;
    const splitText = doc.splitTextToSize(descripcionDocumento, maxWidth);
    let currentY = 30;
    splitText.forEach((line) => {
      doc.text(line, 14, currentY);
      currentY += 7;
    });

    // Balance Final
    doc.setFontSize(14);
    if (balance >= 0) {
      doc.setTextColor(0, 128, 0);
    } else {
      doc.setTextColor(220, 20, 60);
    }
    doc.text(
      `Balance Final: ${balance >= 0 ? `S/ ${balance.toFixed(2)}` : `- S/ ${Math.abs(balance).toFixed(2)}`}`,
      150,
      20
    );
    doc.setTextColor(0, 0, 0);

    if (nivelReporte === "1") {
      // Nivel 1: Tabla y anexos
      doc.autoTable({
        startY: 40,
        tableWidth: "auto",
        head: [["Fecha", "Concepto", "Monto", "Tipo"]],
        body: filteredTransactions.map((tx) => {
          const fechaTx = formatDatePDF(tx.fecha);
          const conceptoText = tx.concepto;
          const montoText = tx.tipo === "Egreso"
            ? `- S/ ${Number(tx.monto).toFixed(2)}`
            : `S/ ${Number(tx.monto).toFixed(2)}`;
          return [fechaTx, conceptoText, montoText, tx.tipo];
        }),
        styles: { fontSize: 10, cellPadding: 3 },
        headStyles: {
          fillColor: [0, 120, 215],
          textColor: [255, 255, 255],
          halign: "center",
          fontStyle: "bold"
        },
        columnStyles: {
          0: { cellWidth: 30, halign: "center" },
          1: { cellWidth: "auto" },
          2: { cellWidth: 30, halign: "right" },
          3: { cellWidth: 30, halign: "center" }
        },
        margin: { left: 14, right: 14 },
        didParseCell: function (data) {
          if (data.section === 'body' && data.column.index === 2) {
            const cellText = data.cell.raw;
            if (typeof cellText === "string" && cellText.trim().startsWith("-")) {
              data.cell.styles.textColor = [220, 20, 60];
            } else {
              data.cell.styles.textColor = [0, 128, 0];
            }
          }
        }
      });
      const hayImagenes = filteredTransactions.some(tx => tx.imagenes && tx.imagenes.length > 0);
      if (hayImagenes) {
        doc.addPage();
        doc.setFontSize(14);
        doc.setTextColor(0, 120, 215);
        doc.text("Anexos de Imágenes", 14, 20);
        let yPos = 30;
        filteredTransactions.forEach((tx) => {
          if (tx.imagenes && tx.imagenes.length > 0) {
            tx.imagenes.forEach((imgDataURL) => {
              try {
                doc.addImage(imgDataURL, 'JPEG', 14, yPos, 80, 60);
                doc.setFontSize(10);
                doc.text(formatDatePDF(tx.fecha), 14, yPos - 2);
                yPos += 70;
                if (yPos > 270) {
                  doc.addPage();
                  yPos = 20;
                }
              } catch (e) {
                console.error("Error al agregar imagen:", e);
              }
            });
          }
        });
      }
      doc.save("reporte_financiero.pdf");
    } else if (nivelReporte === "2") {
      // Nivel 2: Gráficos
    
      // Primer gráfico: Balance Acumulado en el Tiempo
      doc.setTextColor(255, 87, 34);
      doc.setFontSize(12);
      doc.text("Balance Acumulado en el Tiempo", 14, 60);
      doc.setTextColor(0, 0, 0);
      html2canvas(document.getElementById("balance-chart"), { scale: 2 }).then((canvas1) => {
        const imgData1 = canvas1.toDataURL("image/png");
        const imgWidth1 = 180;
        const imgHeight1 = (canvas1.height * imgWidth1) / canvas1.width;
        let currentY = 70;
        doc.addImage(imgData1, "PNG", 14, currentY, imgWidth1, imgHeight1);
        currentY += imgHeight1 + 10;
    
        // Segundo gráfico: PieChart (Análisis por Categoría)
        doc.setTextColor(255, 87, 34);
        doc.setFontSize(12);
        doc.text("Análisis por Categoría", 14, currentY);
        doc.setTextColor(0, 0, 0);
        currentY += 10;
        html2canvas(document.getElementById("pie-chart"), { scale: 2 }).then((canvas2) => {
          const imgData2 = canvas2.toDataURL("image/png");
          const nuevoAncho = 200;
          const nuevoAlto = (canvas2.height * nuevoAncho) / canvas2.width;
          doc.addImage(imgData2, "PNG", 10, currentY, nuevoAncho, nuevoAlto);
          currentY += nuevoAlto + 10;
    
          // Salto de página antes de los gráficos de barras y comparativa
          doc.addPage();
          currentY = 20;
    
          // Tercer gráfico: Gráfico de Barras (Top 5)
          doc.setTextColor(255, 87, 34);
          doc.setFontSize(12);
          doc.text("Análisis de Barras de las 5 categorías con más gastos", 14, currentY);
          doc.setTextColor(0, 0, 0);
          currentY += 10;
          html2canvas(document.getElementById("bar-chart"), { scale: 2 }).then((canvas3) => {
            const imgData3 = canvas3.toDataURL("image/png");
            const barChartWidth = 180;
            const barChartHeight = (canvas3.height * barChartWidth) / canvas3.width;
            doc.addImage(imgData3, "PNG", 14, currentY, barChartWidth, barChartHeight);
            currentY += barChartHeight + 10;
    
            // Cuarto gráfico: Comparativa Ingresos vs Egresos
            doc.setTextColor(255, 87, 34);
            doc.setFontSize(12);
            doc.text("Comparativa Ingresos vs Egresos", 14, currentY);
            doc.setTextColor(0, 0, 0);
            currentY += 10;
            html2canvas(document.getElementById("comparative-chart"), { scale: 2 }).then((canvas4) => {
              const imgData4 = canvas4.toDataURL("image/png");
              const compChartWidth = 180;
              const compChartHeight = (canvas4.height * compChartWidth) / canvas4.width;
              doc.addImage(imgData4, "PNG", 14, currentY, compChartWidth, compChartHeight);
              currentY += compChartHeight + 10;
    
              // Bloque final: Captura del contenedor "wrapped-info"
              html2canvas(document.getElementById("wrapped-info"), { scale: 2 }).then((canvas5) => {
                const imgData5 = canvas5.toDataURL("image/png");
                const wrappedWidth = 180;
                const wrappedHeight = (canvas5.height * wrappedWidth) / canvas5.width;
                doc.addImage(imgData5, "PNG", 14, currentY, wrappedWidth, wrappedHeight);
                currentY += wrappedHeight + 10;
    
                // Finalmente, guarda el PDF (única llamada)
                doc.save("reporte_financiero.pdf");
              });
            });
          });
        });
      });
    
    } else if (nivelReporte === "3") {
      // Nivel 3: Capturamos el resumen de Estado de Resultados como imagen
      html2canvas(document.getElementById("summary-info"), { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 180; // Ajusta el ancho si lo deseas
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        // Coloca la imagen a 14 mm desde la izquierda y 20 mm desde arriba
        doc.addImage(imgData, "PNG", 14, 40, imgWidth, imgHeight);
        doc.save("reporte_financiero.pdf");
      });
    }
    
  };

  if (!fechaDesde || !fechaHasta) {
    return (
      <Container sx={{ mt: 4, pb: 5 }}>
        <Alert severity="error" sx={{ mt: 2 }}>
          No se han recibido las fechas necesarias para generar el reporte.
        </Alert>
        <Button variant="outlined" sx={{ mt: 2 }} onClick={() => navigate(-1)}>
          Volver
        </Button>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4, pb: 5 }}>
      <Paper sx={{ p: 3, position: "relative" }}>
        {/* Botón para Descargar Informe */}
        <Box sx={{ position: "absolute", top: 16, right: 16 }}>
          <Button variant="contained" color="primary" onClick={descargarPDF}>
            📥 DESCARGAR INFORME
          </Button>
        </Box>

        <Typography variant="h4" gutterBottom sx={{ color: "#1976d2", textAlign: "center" }}>
          {tituloDocumento}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2, color: "#616161", textAlign: "center" }}>
          {descripcionDocumento}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            textAlign: "center",
            color: balance >= 0 ? "green" : "red"
          }}
        >
          Balance Final:{" "}
          {balance >= 0 ? `S/ ${balance.toFixed(2)}` : `- S/ ${Math.abs(balance).toFixed(2)}`}
        </Typography>

        {nivelReporte === "1" && (
          <>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Transacciones:
              </Typography>
              {filteredTransactions.length > 0 ? (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Concepto</TableCell>
                        <TableCell align="right">Monto</TableCell>
                        <TableCell>Tipo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredTransactions.map((tx, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{formatDatePDF(tx.fecha)}</TableCell>
                          <TableCell>{tx.concepto}</TableCell>
                          <TableCell align="right" sx={{ color: tx.tipo === "Ingreso" ? "green" : "red" }}>
                            {tx.tipo === "Egreso"
                              ? `- S/ ${Number(tx.monto).toFixed(2)}`
                              : `S/ ${Number(tx.monto).toFixed(2)}`}
                          </TableCell>
                          <TableCell>{tx.tipo}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Alert severity="info">No hay transacciones en este rango.</Alert>
              )}
            </Box>
            <Box sx={{ mt: 3, p: 2, borderTop: "1px dashed #ccc" }}>
              <Typography variant="h6">Anexos:</Typography>
              <Typography variant="body2" color="textSecondary">
                (Aquí se mostrarían las imágenes asociadas, las visualizarás al descargar el informe.)
              </Typography>
            </Box>
          </>
        )}

        {nivelReporte === "2" && (
          <>
            <Typography variant="h5" sx={{ mt: 4, color: "#1976d2", textAlign: "center" }}>
              Balance Acumulado en el Tiempo
            </Typography>
            <div id="balance-chart">
              <Box sx={{ width: "100%", height: 300, mt: 2 }}>
                <ResponsiveContainer>
                  <LineChart data={lineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="fecha"
                      tickFormatter={(value) => formatDatePDF(value)}
                      ticks={getXTicks(lineData)}
                    />
                    <YAxis tickCount={10} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="balance" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </div>

            {/* Gráfico PieChart */}
            <div id="pie-chart">
              <Box sx={{ width: "100%", height: 300, mt: 2 }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={pieData}
                      dataKey="total"
                      nameKey="category"
                      cx="50%"
                      cy="50%"
                      outerRadius={80}
                      label
                    >
                      {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend />
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </div>

            {/* Gráfico de Barras */}
            <div id="bar-chart" style={{ width: "100%", height: 300, marginTop: 20 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={barData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="category" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="total" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* Gráfico Comparativo */}
            <div id="comparative-chart" style={{ width: "100%", height: 300, marginTop: 20 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={comparativeData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis 
                    dataKey="fecha" 
                    tickFormatter={(value) => formatDatePDF(value)}
                    ticks={getXTicks(comparativeData)}
                  />
                  <YAxis tickCount={10} tickFormatter={(value) => Math.round(value)} />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="ingresos" stroke="#008000" name="Ingresos" />
                  <Line type="monotone" dataKey="egresos" stroke="#FF0000" name="Egresos" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </>
        )}

{nivelReporte === "3" && (
  <Box
    id="summary-info"
    sx={{
      mt: 4,
      p: 3,
      border: "2px solid #1976d2",
      borderRadius: 2,
      backgroundColor: "#e3f2fd",
      minHeight: "70vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    <Typography variant="h4" sx={{ mb: 2, color: "#1976d2" }}>
      Resumen del Estado de Resultados
    </Typography>
    <Typography variant="h5" sx={{ mb: 1 }}>
      Ingresos: S/ {ingresos.toFixed(2)}
    </Typography>
    <Typography variant="h5" sx={{ mb: 1 }}>
      Otros Ingresos: S/ {otrosIngresos.toFixed(2)}
    </Typography>
    <Typography variant="h5" sx={{ mb: 1 }}>
      Costo de Ventas (COGS): S/ {cogs.toFixed(2)}
    </Typography>
    <Typography variant="h5" sx={{ mb: 1 }}>
      Gasto Operativo: S/ {gastoOperativo.toFixed(2)}
    </Typography>
    <Typography variant="h5" sx={{ mb: 1, fontWeight: "bold" }}>
      Utilidad Neta: S/ {utilidadNeta.toFixed(2)}
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
      Recuerda que este es solo un resumen de tus datos. ¡Explora tus gráficos y analiza tus gastos a fondo para tomar mejores decisiones financieras!
    </Typography>
  </Box>
)}

        {/* Bloque para mostrar "Finanzas Wrapped" en la interfaz */}
        <Box
          id="wrapped-info"
          sx={{
            mt: 3,
            p: 2,
            border: "1px solid #ccc",
            borderRadius: 1,
            backgroundColor: "#f9f9f9"
          }}
        >
          <Typography variant="h6" sx={{ color: "#1976d2", mb: 1 }}>
            Finanzas Wrapped
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
            {`Aquí algunos datos que podrían interesarte:

⚠️ ¿Sabías que el día que más gastaste fue el ${maxExpenseTx ? formatDatePDF(maxExpenseTx.fecha) : 'N/A'}? ¡El concepto fue "${maxExpenseTx ? maxExpenseTx.concepto : 'N/A'}"! ¿Celebración o antojo?

🚀 ¡Eres constante! Tu ingreso promedio diario es de S/ ${ingresoPromedio.toFixed(2)}. ¡Mantén el ritmo!

🐜 ¡Ojo con los gastos hormiga! Tu gasto promedio diario es de S/ ${gastoPromedio ? gastoPromedio.toFixed(2) : 'N/A'}. ¡Recuerda que cada granito cuenta!

💰 ¡Qué alegría cuando llega el dinero! Tu día con mayores ingresos fue el ${maxIncomeTx ? formatDatePDF(maxIncomeTx.fecha) : 'N/A'}, con un total de S/ ${maxIncomeTx ? Number(maxIncomeTx.monto).toFixed(2) : 'N/A'}. ¡A disfrutarlo con responsabilidad!

💸 La categoría en la que más gastaste es "${topExpenseCategory ? topExpenseCategory : 'N/A'}", con un total de S/ ${topExpenseCategoryAmount ? topExpenseCategoryAmount.toFixed(2) : 'N/A'}. ¿Será que estás invitando mucho a tus amigos?

📝 ¡Eres una persona organizada! Registraste un total de ${filteredTransactions.length} transacciones. ¡Eso te permite tener una visión clara de tus finanzas!

Recuerda que este es solo un resumen de tus datos. ¡Explora tus gráficos y analiza tus gastos a fondo para tomar mejores decisiones financieras!`}


          </Typography>
        </Box>



        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>
            Volver
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default ReporteGenerado;
