import axios from 'axios';

const api = axios.create({
  baseURL: 'https://lopsided-baby-production.up.railway.app', // Asegúrate de que este es el puerto correcto de tu backend
  headers: {
    'Content-Type': 'application/json',
  },
});

// Función para iniciar sesión
export const login = async (email, password) => {
    try {
        const response = await api.post("/login", { email, password });

        if (!response.data) {
            throw new Error("Error en la solicitud");
        }

        return response.data;
    } catch (error) {
        console.error("Error en la solicitud:", error);
        return { mensaje: "Error en la solicitud" };
    }
};

export default api;
