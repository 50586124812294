import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function ModalGestionCategorias({ open, onClose, categorias, onGuardar = () => {}, onEliminar }) {
  const [modoEdicion, setModoEdicion] = useState(false);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
  const [nombreCategoria, setNombreCategoria] = useState("");
  const [tipoCategoria, setTipoCategoria] = useState("categoria");
  const [categoriaPadre, setCategoriaPadre] = useState("");
  const categoriasSeguras = categorias || [];

  // Función para manejar la edición de una categoría existente
  const handleEditar = (categoria) => {
    setModoEdicion(true);
    setCategoriaSeleccionada(categoria);
    setNombreCategoria(categoria.nombre);
    setTipoCategoria(categoria.tipo);
    setCategoriaPadre(categoria.parent || "");
  };

  // Función para manejar la eliminación de una categoría
  const handleEliminar = (categoria) => {
    if (window.confirm(`¿Seguro que quieres eliminar la categoría "${categoria.nombre}"?`)) {
      onEliminar(categoria);
    }
  };

  // Guardar cambios o nueva categoría
  const handleGuardar = () => {
    if (!nombreCategoria.trim()) {
      alert("Debes ingresar un nombre para la categoría.");
      return;
    }
    if (tipoCategoria === "subcategoria" && !categoriaPadre) {
      alert("Debes seleccionar una categoría padre para la subcategoría.");
      return;
    }

    const nuevaCategoria = {
      nombre: nombreCategoria.trim(),
      tipo: tipoCategoria,
      parent: tipoCategoria === "subcategoria" ? categoriaPadre : null,
    };

    console.log("🚀 Nueva categoría a guardar:", nuevaCategoria);

    if (typeof onGuardar === "function") {
      onGuardar(
        (prevCategorias) => {
          if (modoEdicion) {
            // Reemplaza la categoría original por la nueva
            return prevCategorias.map((cat) =>
              cat.nombre === categoriaSeleccionada.nombre ? nuevaCategoria : cat
            );
          } else {
            // Agrega la nueva categoría al array
            return [...(prevCategorias || []), nuevaCategoria];
          }
        },
        modoEdicion ? categoriaSeleccionada : null,
        modoEdicion ? nuevaCategoria : null
      );
    } else {
      console.error("❌ Error: `onGuardar` no es una función válida", onGuardar);
    }

    setModoEdicion(false);
    setCategoriaSeleccionada(null);
    setNombreCategoria("");
    setTipoCategoria("categoria");
    setCategoriaPadre("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: 500,
          overflowY: "auto",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" gutterBottom>
          Gestionar Categorías
        </Typography>

        {/* Caja para la lista de categorías con scroll */}
        <Box sx={{ maxHeight: 200, overflowY: "auto", mb: 2 }}>
          <List>
            {(categoriasSeguras || []).map((cat, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={cat.nombre}
                  primaryTypographyProps={{ color: cat.tipo === "categoria" ? "red" : "black" }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleEditar(cat)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleEliminar(cat)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Formulario para agregar o editar una categoría */}
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Nombre de la Categoría *"
            value={nombreCategoria}
            onChange={(e) => {
              const nuevoTexto = e.target.value.slice(0, 30);
              setNombreCategoria(nuevoTexto);
            }}
            margin="normal"
            required
            inputProps={{ maxLength: 30 }}
            helperText={`${30 - nombreCategoria.length} caracteres restantes`}
            autoComplete="off"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Clasificación</InputLabel>
            <Select
              value={tipoCategoria}
              onChange={(e) => setTipoCategoria(e.target.value)}
            >
              <MenuItem value="categoria">Categoría</MenuItem>
              <MenuItem value="subcategoria" disabled={categoriasSeguras.length === 0}>
                Subcategoría
              </MenuItem>
            </Select>
          </FormControl>
          {tipoCategoria === "subcategoria" && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Categoría Padre *</InputLabel>
              <Select
                value={categoriaPadre}
                onChange={(e) => setCategoriaPadre(e.target.value)}
                required
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      width: 300,
                      overflowY: "auto",
                    },
                  },
                }}
              >
                {categoriasSeguras
                  .filter((cat) => !cat.parent)
                  .map((cat, index) => (
                    <MenuItem key={index} value={cat.nombre}>
                      {cat.nombre}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleGuardar}
        >
          {modoEdicion ? "GUARDAR CAMBIOS" : "AÑADIR CATEGORÍA"}
        </Button>
      </Box>
    </Modal>
  );
}

export default ModalGestionCategorias;
