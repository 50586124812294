// SolicitudAmpliacion.js

import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  onSnapshot
} from "firebase/firestore";
import { db } from "../firebase/firebase.js";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PaymentIcon from "@mui/icons-material/Payment";
// Importar funciones de Storage para subir la imagen y obtener su URL
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const SolicitudAmpliacion = () => {
  // Estados del formulario
  const [suscripcion, setSuscripcion] = useState("");
  const [comprobante, setComprobante] = useState(null);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  // Estado que indica si existe una solicitud pendiente para este usuario (status "pendiente")
  const [solicitudPendiente, setSolicitudPendiente] = useState(false);
  const [loadingSolicitud, setLoadingSolicitud] = useState(true);
  // Estados de loading para botones
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [loadingRecargar, setLoadingRecargar] = useState(false);
  // Estado para controlar si la verificación se hizo manualmente (para alertar)
  const [manualRecarga, setManualRecarga] = useState(false);
  // Opciones para el dropdown de suscripción
  const suscripcionOptions = [
    { value: "1 año", label: "1 año" },
    { value: "2 años", label: "2 años" },
    { value: "3 años", label: "3 años" },
    { value: "4 años", label: "4 años" },
    { value: "5 años", label: "5 años" }
  ];

  const navigate = useNavigate();
  const auth = getAuth();
  const [usuario, setUsuario] = useState(null);
  // Estado para almacenar datos del usuario (incluye el "ID" informativo y creditos) desde la colección "usuarios"
  const [datosUsuario, setDatosUsuario] = useState(null);

  // Estado para el modal del QR de YAPE
  const [openModalYape, setOpenModalYape] = useState(false);
  // Estado para mensajes del superadministrador (bandeja de mensajes)
  const [mensajesSuper, setMensajesSuper] = useState([]);

  // --- Restablecer la sesión con onAuthStateChanged ---
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUsuario(firebaseUser);
      } else {
        navigate("/login");
      }
    });
    return unsubscribe;
  }, [navigate, auth]);

  // --- Cargar datos del usuario desde "usuarios" (para obtener el "ID" informativo y creditos) ---
  useEffect(() => {
    if (usuario) {
      const userDocRef = doc(db, "usuarios", usuario.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          setDatosUsuario(data);
          setMensajesSuper(data.messages || []);
        } else {
          setMensajesSuper([]);
        }
      }, (error) => {
        console.error("Error en onSnapshot:", error);
      });
      return () => unsubscribe();
    }
  }, [usuario]);
  
  

  // --- Función para verificar si existe una solicitud pendiente ---
  const verificarSolicitud = async () => {
    if (!usuario) {
      navigate("/login");
      return;
    }
    try {
      const docRef = doc(db, "solicitudesAmpliacion", usuario.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.status === "pendiente") {
          if (manualRecarga) {
            alert("No se detectaron cambios en el estado de la solicitud.");
          }
          setSolicitudPendiente(true);
        } else {
          setSolicitudPendiente(false);
        }
      } else {
        setSolicitudPendiente(false);
      }
    } catch (err) {
      console.error("Error al verificar la solicitud:", err);
    } finally {
      setLoadingSolicitud(false);
      setManualRecarga(false);
    }
  };

  useEffect(() => {
    if (usuario) {
      verificarSolicitud();
    }
  }, [usuario]);

  // --- Función para recargar el estado manualmente ---
  const handleRecargarEstado = async () => {
    setManualRecarga(true);
    setLoadingRecargar(true);
    await verificarSolicitud();
    setLoadingRecargar(false);
  };

  // --- Función para manejar la carga del comprobante (archivo) ---
  const handleComprobanteChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setComprobante(e.target.files[0]);
    }
  };

  // --- Función para subir el comprobante a Firebase Storage y obtener su URL ---
  const uploadComprobante = async () => {
    if (!comprobante) return "";
    const storage = getStorage();
    // Se crea una referencia en Storage: carpeta "comprobantes", subcarpeta con el UID y se añade un timestamp al nombre
    const storageRef = ref(storage, `comprobantes/${usuario.uid}/${comprobante.name}_${Date.now()}`);
    try {
      const snapshot = await uploadBytes(storageRef, comprobante);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error al subir el comprobante:", error);
      throw error;
    }
  };

  // --- Función para enviar la solicitud ---
  const handleEnviarSolicitud = async (e) => {
    e.preventDefault();
    setError("");
    setMensaje("");
    setLoadingEnviar(true);

    // Verificar si el usuario ya tiene suficientes días (creditos > 15)
    if (datosUsuario && datosUsuario.creditos > 15) {
      setError(
        "Usted ya cuenta con una suscripción activa, ¿desea aumentar sus créditos? Favor de comunicarse al WhatsApp: +51 906 115 152"
      );
      setLoadingEnviar(false);
      return;
    }

    if (!suscripcion) {
      setError("Seleccione el tiempo de suscripción adquirido.");
      setLoadingEnviar(false);
      return;
    }
    if (!comprobante) {
      setError("Adjunte el comprobante de pago.");
      setLoadingEnviar(false);
      return;
    }

    try {
      // Subir el comprobante a Storage y obtener la URL
      const comprobanteURL = await uploadComprobante();

      const solicitud = {
        uid: usuario.uid, // para operaciones internas
        idUserCustom: datosUsuario ? datosUsuario.id : null, // el ID informativo (número de 7 dígitos)
        suscripcion, // Ejemplo: "1 año"
        comprobanteNombre: comprobante.name,
        comprobanteURL, // URL obtenida desde Storage
        fechaSolicitud: new Date().toISOString(),
        status: "pendiente",
        creditosActuales: datosUsuario ? datosUsuario.creditos : 0
      };

      // Guardamos la solicitud usando el UID del usuario (única solicitud por día)
      await addDoc(collection(db, "solicitudesAmpliacion"), solicitud);
      setMensaje(
        "Su solicitud fue enviada con éxito, en menos de 48 horas tendrá respuesta. Puede revisar esta página en cualquier momento."
      );
      setSolicitudPendiente(true);
    } catch (err) {
      console.error("Error al enviar la solicitud:", err);
      setError("Error al enviar la solicitud. Inténtelo nuevamente.");
    } finally {
      setLoadingEnviar(false);
    }
  };

  // --- Renderizado ---
  if (loadingSolicitud) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5" align="center">Cargando...</Typography>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={6}
          sx={{
            p: 4,
            borderRadius: 3,
            background:
              "linear-gradient(135deg, rgba(255,255,255,0.95) 0%, rgba(240,248,255,0.95) 100%)"
          }}
        >
          {/* Encabezado e instrucciones */}
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ fontWeight: "bold", color: "#d32f2f" }}
          >
            🔥 Accede hoy por un año con un único pago accesible 🔥
          </Typography>

          <Typography variant="h6" align="center" sx={{ mb: 2, color: "#1976d2" }}>
            Ofrecemos dos opciones de pago según tu ubicación:
          </Typography>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            {/* Columna para YAPE */}
            <Grid item xs={12} md={6}>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  borderRadius: 2,
                  backgroundColor: "#fce4ec",
                  boxShadow: "0px 2px 8px rgba(0,0,0,0.1)"
                }}
              >
                <Typography variant="body1" gutterBottom sx={{ fontWeight: "medium" }}>
                  <QrCodeIcon sx={{ verticalAlign: "middle", mr: 1, color: "#c2185b" }} />
                  Paga con YAPE 35 soles y envía tu solicitud con captura de pantalla.
                  (¡ESTO ES MENOS DE 3 SOLES AL MES!) 🔥
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 1,
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" }
                  }}
                  onClick={() => setOpenModalYape(true)}
                  disabled={loadingEnviar || loadingRecargar}
                >
                  {loadingEnviar ? "Cargando..." : "Ver QR de YAPE"}
                </Button>
              </Paper>
            </Grid>

            {/* Columna para MercadoPago */}
            <Grid item xs={12} md={6}>
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  borderRadius: 2,
                  backgroundColor: "#e3f2fd",
                  boxShadow: "0px 2px 8px rgba(0,0,0,0.1)"
                }}
              >
                <Typography variant="body1" gutterBottom sx={{ fontWeight: "medium" }}>
                  <PaymentIcon sx={{ verticalAlign: "middle", mr: 1, color: "#1565c0" }} />
                  Paga con MercadoPago 11 USD y envía tu solicitud con captura de pantalla.
                  (¡ESTO ES MENOS DE $1 AL MES!) 🔥
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 1,
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" }
                  }}
                  href="https://mpago.la/11kTuZC"
                  target="_blank"
                  disabled={loadingEnviar || loadingRecargar}
                >
                  Pagar con MercadoPago
                </Button>
              </Paper>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Typography variant="body2" sx={{ color: "#424242", mb: 1 }}>
              📌 Si pasadas las 48 horas tu acceso aún no ha sido actualizado, escríbenos por WhatsApp al{" "}
              <strong>+51 906 115 152</strong> para agilizar la verificación.
            </Typography>
            <Typography variant="body2" sx={{ color: "#424242" }}>
              🔒 Pago seguro y garantizado – Puedes aprovechar el precio de promoción y pagar por más de 1 año. 
              El contrato se respetará incluso si más adelante el precio sube.
            </Typography>
          </Box>

          <Box sx={{ mt: 4, textAlign: "center" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2e7d32" }}>
              {solicitudPendiente
                ? "¡Tu solicitud fue enviada con éxito! Espere la respuesta de los administradores."
                : "Si realizaste el pago, completa la solicitud ⬇️"}
            </Typography>
          </Box>

          {/* Formulario para enviar solicitud (solo se muestra si no hay solicitud pendiente) */}
          {!solicitudPendiente && (
            <form onSubmit={handleEnviarSolicitud}>
              <TextField
                select
                label="Tiempo de suscripción adquirido"
                value={suscripcion}
                onChange={(e) => setSuscripcion(e.target.value)}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                required
              >
                {suscripcionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" component="label" color="secondary" disabled={loadingEnviar || loadingRecargar}>
                  {loadingEnviar ? "Subiendo comprobante..." : "Adjuntar comprobante"}
                  <input type="file" accept="image/*" hidden onChange={handleComprobanteChange} />
                </Button>
                {comprobante && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    Archivo: {comprobante.name}
                  </Typography>
                )}
              </Box>
              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              {mensaje && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  {mensaje}
                </Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 3, py: 1.5 }}
                disabled={loadingEnviar || loadingRecargar}
              >
                {loadingEnviar ? "Enviando solicitud..." : "Enviar solicitud"}
              </Button>
            </form>
          )}

          {/* Botón para recargar el estado si hay solicitud pendiente */}
          {solicitudPendiente && (
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <Button variant="outlined" onClick={handleRecargarEstado} disabled={loadingRecargar}>
                {loadingRecargar ? "Recargando..." : "Recargar estado"}
              </Button>
            </Box>
          )}

          {/* Mensaje promocional */}
          <Box sx={{ mt: 3, textAlign: "center" }}>
            <Typography variant="caption" sx={{ color: "#555" }}>
              Aprovecha nuestro precio de promoción para solicitar más de 1 año y asegurar el servicio por el tiempo contratado, sin importar si sube el precio más adelante.
            </Typography>
          </Box>

          {/* Bandeja de mensajes del superadministrador */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: "medium" }}>
              Mensajes del Superadministrador:
            </Typography>
            {mensajesSuper.length === 0 ? (
  <Alert severity="info">No hay mensajes.</Alert>
) : (
  mensajesSuper.map((msg, index) => (
    <Alert
      key={index}
      severity={msg.type === "aprobacion" ? "success" : msg.type === "rechazo" ? "error" : "info"}
      sx={{ mt: 1 }}
    >
      {msg.text} - {new Date(msg.date).toLocaleString()}
    </Alert>
  ))
)}

          </Box>
        </Paper>
      </Container>
      
      {/* Modal para mostrar el QR de YAPE */}
      <Dialog open={openModalYape} onClose={() => setOpenModalYape(false)}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Pago con YAPE
          <IconButton
            aria-label="close"
            onClick={() => setOpenModalYape(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ textAlign: "center" }}>
  <Typography variant="body1" gutterBottom>
    📲 Realiza tu pago fácilmente
  </Typography>
  <Typography variant="body1" gutterBottom>
    🔹 Yapea o haz un Plin al número 906 115 152
  </Typography>
  <Typography variant="body1" gutterBottom>
    🔹 A nombre de: Gean Miranda
  </Typography>
  <Typography variant="body1" gutterBottom>
    🔹 También puedes escanear el código QR de abajo 📸
  </Typography>
  <Typography variant="body2" sx={{ mt: 2, mb: 2 }}>
    📌 Recuerda: Guarda una captura de pantalla de tu pago para enviarla en el formulario y activar tu acceso.
  </Typography>
  <Box
    component="img"
    src="/yapeQR.png"  // Reemplaza esta URL con la imagen real del QR
    alt="Código QR de YAPE"
    sx={{ width: "100%", maxWidth: 300, mx: "auto", borderRadius: 2, boxShadow: 3 }}
  />
</DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenModalYape(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SolicitudAmpliacion;
