import { db } from "./firebase.js";
import { collection, getDocs, setDoc, doc, deleteDoc, addDoc, updateDoc, getDoc } from "firebase/firestore";

// ✅ Obtener todos los conceptos de una tabla financiera
export async function obtenerConceptos(idTabla) {
  const docRef = doc(db, "tablasFinancieras", idTabla);
  const subcolRef = collection(docRef, "conceptos");
  const querySnapshot = await getDocs(subcolRef);

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

// ✅ Agregar un concepto nuevo a Firestore
export async function agregarConcepto(idTabla, nuevoConcepto) {
  const docRef = doc(db, "tablasFinancieras", idTabla);
  const subcolRef = collection(docRef, "conceptos");
  await addDoc(subcolRef, nuevoConcepto);
}

// ✅ Actualizar un concepto en Firestore
export async function actualizarConcepto(idTabla, idConcepto, data) {
  const docRef = doc(db, "tablasFinancieras", idTabla);
  const subcolRef = collection(docRef, "conceptos");
  const conceptoRef = doc(subcolRef, idConcepto);
  await updateDoc(conceptoRef, data);
}

// ✅ Eliminar un concepto de Firestore
export async function eliminarConcepto(idTabla, idConcepto) {
  const docRef = doc(db, "tablasFinancieras", idTabla);
  const subcolRef = collection(docRef, "conceptos");
  const conceptoRef = doc(subcolRef, idConcepto);
  await deleteDoc(conceptoRef);
}

// ✅ Obtener todas las categorías desde Firestore
// ✅ Obtener todas las categorías desde Firestore
export async function obtenerCategorias(nombreTabla) {
  const categoriasRef = collection(db, `categorias_${nombreTabla}`);
  const snapshot = await getDocs(categoriasRef);
  // Si existe un documento con la propiedad "categorias", asumimos que es el único documento y devolvemos ese array.
  if (snapshot.docs.length === 1) {
    const data = snapshot.docs[0].data();
    if (data && Array.isArray(data.categorias)) {
      return data.categorias;
    }
  }
  // Si no, devolvemos lo que se encuentre (esto sería un array de objetos)
  return snapshot.docs.map(doc => doc.data());
}


// ✅ Guardar o actualizar las categorías en Firestore
export async function guardarCategoria(nombreTabla, nuevaCategoria) {
  // Usamos un documento fijo con ID "datos" dentro de la colección `categorias_${nombreTabla}`
  const categoriasDocRef = doc(db, `categorias_${nombreTabla}`, "datos");
  await setDoc(categoriasDocRef, { categorias: nuevaCategoria });
}



// ✅ Obtener todas las tablas financieras de un usuario
export async function obtenerTablasFinancieras(uid) {
  const tablasRef = collection(db, "usuarios", uid, "tablasFinancieras");
  const snapshot = await getDocs(tablasRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}


// ✅ Guardar o actualizar una tabla financiera usando subcolecciones por usuario
export async function guardarTabla(id, datosTabla, uid) {
  if (id) {
    // Si se está actualizando una tabla, se asume que ya se conoce su ruta en la colección global.
    // Si también quieres actualizarla en la subcolección, podrías replicar el cambio allí.
    await setDoc(doc(db, "usuarios", uid, "tablasFinancieras", id), datosTabla);
  } else {
    // Para crear una nueva tabla, se guarda en la subcolección del usuario
    const docRef = await addDoc(collection(db, "usuarios", uid, "tablasFinancieras"), {
      ...datosTabla,
      fechaCreacion: new Date().toISOString(),
    });
    return docRef.id;
  }
}


export async function eliminarTabla(uid, id) {
  await deleteDoc(doc(db, "usuarios", uid, "tablasFinancieras", id));
}


// ✅ Obtener configuración de usuario correctamente
export async function obtenerConfiguracionUsuario(userId) {
  const docRef = doc(db, "configuracionUsuarios", userId);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data() : null;
}

// ✅ Guardar configuración de usuario
export async function guardarConfiguracionUsuario(userId, configuracion) {
  await setDoc(doc(db, "configuracionUsuarios", userId), configuracion);
}
