import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import {
  AddCircleOutline,
  ArrowBack,
  BarChart,
  Settings,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon
} from "@mui/icons-material";
import Navbar from "../components/Navbar.js";
import ModalNuevoConcepto from "../components/ModalNuevoConcepto.js";
import ModalGestionCategorias from "../components/ModalGestionCategorias.js";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ListIcon from '@mui/icons-material/List';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import { obtenerConceptos, agregarConcepto, actualizarConcepto, eliminarConcepto } from "../firebase/firebaseService.js";
import { obtenerConfiguracionUsuario, guardarConfiguracionUsuario } from "../firebase/firebaseService.js";
import { obtenerCategorias } from "../firebase/firebaseService.js";
import { guardarCategoria } from "../firebase/firebaseService.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Función para formatear la fecha de "YYYY-MM-DD" a "DD/MM/YYYY" tratándola como fecha local
function formatDate(dateString) {
  if (!dateString) return "";
  // Se asume que dateString está en formato "YYYY-MM-DD"
  const [year, month, day] = dateString.split("-");
  // Crea un objeto Date usando el constructor que trata los parámetros como hora local
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  // Formatea la fecha con dos dígitos para el día y mes
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const yyyy = date.getFullYear();
  return `${dd}/${mm}/${yyyy}`;
}



// Función para formatear el monto con símbolo, separadores de miles y dos decimales
function formatAmount(amount, moneda) {
  const number = Number(amount);
  if (isNaN(number)) return amount;
  let symbol = "";
  switch (moneda) {
    case "PEN":
      symbol = "S/";
      break;
    case "USD":
      symbol = "$";
      break;
    case "EUR":
      symbol = "€";
      break;
    case "CLP":
      symbol = "CLP$";
      break;
    case "MXN":
      symbol = "MX$";
      break;
    default:
      symbol = moneda;
  }
  return `${symbol} ${number.toLocaleString("es-PE", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}


function TablaFinanciera() {
  const navigate = useNavigate();
  const { idTabla } = useParams();
  const location = useLocation();
  const { tituloTabla } = location.state || {};
  const [modalOpen, setModalOpen] = useState(false);
  const [modalGestionCategoriasOpen, setModalGestionCategoriasOpen] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [conceptos, setConceptos] = useState([]);

  // Estado para editar concepto (se reutiliza el modal de nuevo concepto)
  const [conceptoAEditar, setConceptoAEditar] = useState(null);

  // Estado para el modal de imagen ampliada
  const [imagenModalOpen, setImagenModalOpen] = useState(false);
  const [imagenUrl, setImagenUrl] = useState("");

  // Estado para confirmar eliminación
  const [conceptoAEliminar, setConceptoAEliminar] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  // Función para calcular el nivel de un concepto
const computeNivel = (concepto) => {
  // Definimos las alternativas válidas para que sea Nivel 3
  const allowedNivel3 = ["ingresos", "otros ingresos", "costo de ventas (cogs)", "gasto operativo"];
  // Normalizamos la clasificación: si existe, la convertimos a minúsculas y eliminamos espacios extras; si no, cadena vacía.
  const classification = concepto.clasificacion ? concepto.clasificacion.toLowerCase().trim() : "";
  // Si el concepto tiene una categoría válida (no es "Sin categoría")
  if (concepto.categoria && concepto.categoria !== "Sin categoría") {
    // Si la clasificación está en allowedNivel3, retorna "3"; de lo contrario, retorna "2"
    return allowedNivel3.includes(classification) ? "3" : "2";
  }
  // Si no tiene categoría válida, retorna "1"
  return "1";
};


  const [filterValues, setFilterValues] = useState({
    concepto: "",
    tipo: "",
    fechaDesde: "",
    fechaHasta: "",
    montoMin: "",
    montoMax: "",
    estadoFinanciero: "", // Opciones: "Ingresos", "Otros Ingresos", "COGS", "Gasto Operativo", "Sin clasificación", o "" para todos
    categoria: "", // Filtro por categoría
    moneda: "",    // Filtro por moneda (por ejemplo: "PEN", "USD", etc.)
    conImagen: ""  // Valores: "con", "sin", o "" para ambos
    // Nivel: Opcionalmente, "1", "2", "3", o "" para todos
  });
  

  const [openFilters, setOpenFilters] = useState(false);


  // Nuevo estado: control de visibilidad de columnas
  const [visibleColumns, setVisibleColumns] = useState({
    fecha: true,
    concepto: true,
    monto: true,
    tipo: true,
    nivel: true,
    estadoFinanciero: true,
    categoria: true,
    moneda: true,
    imagen: true,
    acciones: true,
  });

  const [loadingConceptos, setLoadingConceptos] = useState(true);

  const [imageCarouselImages, setImageCarouselImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const cargarConfiguracionColumnas = async () => {
      try {
        const configFirestore = await obtenerConfiguracionUsuario("visibleColumns");
        if (configFirestore) setVisibleColumns(configFirestore);
      } catch (error) {
        console.error("Error cargando configuración de columnas desde Firestore:", error);
      }
    };
    cargarConfiguracionColumnas();
  }, []);
  
  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const conceptosFirestore = await obtenerConceptos(idTabla);
        setConceptos(conceptosFirestore);
      } catch (error) {
        console.error("Error cargando conceptos:", error);
      }
    };
  
    cargarDatos();
  }, [idTabla]);
  

  useEffect(() => {
    const guardarConfiguracionColumnas = async () => {
      try {
        await guardarConfiguracionUsuario("visibleColumns", visibleColumns);
      } catch (error) {
        console.error("Error guardando configuración de columnas en Firestore:", error);
      }
    };
    guardarConfiguracionColumnas();
  }, [visibleColumns]);
  
  

// Estado para controlar el menú desplegable del botón "Visualizar"
const [anchorElVisualizar, setAnchorElVisualizar] = useState(null);

// Funciones para abrir y cerrar el menú
const handleVisualizarClick = (event) => {
  setAnchorElVisualizar(event.currentTarget);
};

const handleVisualizarClose = () => {
  setAnchorElVisualizar(null);
};

const handleOrdenarClick = (event) => {
  setAnchorElOrdenar(event.currentTarget);
};

const handleOrdenarClose = () => {
  setAnchorElOrdenar(null);
};

const selectOrderCriteria = (field, direction) => {
  setOrderCriteria({ field, direction });
  setAnchorElOrdenar(null);
};


// Función para alternar la visibilidad de una columna
const toggleColumn = (columna) => {
  setVisibleColumns((prev) => ({
    ...prev,
    [columna]: !prev[columna],
  }));
};


  // --- useEffect y funciones para cargar categorías y conceptos ---
  useEffect(() => {
    const cargarCategorias = async () => {
      try {
        const categoriasFirestore = await obtenerCategorias(idTabla);
        setCategorias(categoriasFirestore);
      } catch (error) {
        console.error("Error cargando categorías desde Firestore:", error);
        setCategorias([]);
      }
    };
    cargarCategorias();
  }, [idTabla]);
  

  useEffect(() => {
    const cargarConceptos = async () => {
      try {
        const conceptosFirestore = await obtenerConceptos(idTabla);
        setConceptos(conceptosFirestore);
      } catch (error) {
        console.error("Error cargando conceptos desde Firestore:", error);
        setConceptos([]);
      } finally {
        setLoadingConceptos(false);
      }
    };
    cargarConceptos();
  }, [idTabla]);
  
  

  // Función para actualizar conceptos
  const actualizarConceptosFirestore = async (nuevosConceptos) => {
    try {
      for (const concepto of nuevosConceptos) {
        if (concepto.id) {
          // Actualizar concepto existente
          await actualizarConcepto(idTabla, concepto.id, concepto);
        } else {
          // Agregar nuevo concepto
          await agregarConcepto(idTabla, concepto);
        }
      }
  
      // Recargar conceptos desde Firestore después de actualizar
      const conceptosFirestore = await obtenerConceptos(idTabla);
      setConceptos(conceptosFirestore);
  
      console.log("✅ Conceptos actualizados en Firestore.");
    } catch (error) {
      console.error("❌ Error actualizando conceptos en Firestore:", error);
    }
  };
  

  // Función para abrir el modal de imagen ampliada
  const handleImagenClick = (imagesArray, index) => {
    setImageCarouselImages(imagesArray);
    setCurrentImageIndex(index);
    setImagenModalOpen(true);
  };
  

  // Función para iniciar la edición de un concepto
  const handleEditarConcepto = (concepto) => {
    console.log("Editar concepto:", concepto);
    setConceptoAEditar(concepto);
    setModalOpen(true);
  };

  // Función para iniciar la eliminación de un concepto
  const handleEliminarConcepto = (concepto) => {
    setConceptoAEliminar(concepto);
    setConfirmDeleteOpen(true);
  };

  // Confirmación de eliminación
  const confirmarEliminacion = async () => {
    if (!conceptoAEliminar) return;
  
    try {
      await eliminarConcepto(idTabla, conceptoAEliminar.id); // Elimina de Firestore
      setConceptos((prev) => prev.filter(c => c.id !== conceptoAEliminar.id));
      setConfirmDeleteOpen(false);
      setConceptoAEliminar(null);
    } catch (error) {
      console.error("❌ Error al eliminar concepto en Firestore:", error);
    }
  };

  const [monedaSeleccionada, setMonedaSeleccionada] = useState(() => {
    return localStorage.getItem("monedaSeleccionada") || "PEN";
  });
    const [anchorElMoneda, setAnchorElMoneda] = useState(null); // Estado para el menú desplegable de moneda
  const handleMonedaClick = (event) => {
    setAnchorElMoneda(event.currentTarget);
  };
  
  const [orderCriteria, setOrderCriteria] = useState({
    field: "",        // Puede ser "fecha" o "monto". "" significa sin orden.
    direction: "asc", // "asc" para ascendente, "desc" para descendente.
  });
  
  const handleMonedaClose = () => {
    setAnchorElMoneda(null);
  };
  
  const [anchorElOrdenar, setAnchorElOrdenar] = useState(null);

  // Estado para controlar si se oculta el balance
const [balanceHidden, setBalanceHidden] = useState(false);

// Estado para la moneda en la que se visualiza el balance
const [balanceCurrency, setBalanceCurrency] = useState(() => {
  // Inicialmente, usaremos la moneda seleccionada
  return monedaSeleccionada;
});

// Estado para el menú desplegable del botón "Balance" (cuando hay más de una moneda)
const [anchorElBalance, setAnchorElBalance] = useState(null);

// Helper: Función para calcular el balance para una moneda dada
const computeBalance = (currency) => {
  return conceptos.reduce((acc, concepto) => {
    // Considera la moneda del concepto; si no existe, asume "PEN"
    if ((concepto.moneda || "PEN") === currency) {
      return concepto.tipo === "Ingreso" ? acc + Number(concepto.monto) : acc - Number(concepto.monto);
    }
    return acc;
  }, 0);
};

// useMemo para calcular el balance en la moneda actual
const balance = React.useMemo(() => {
  return computeBalance(balanceCurrency);
}, [conceptos, balanceCurrency]);

// useMemo para determinar las monedas únicas en los registros
const uniqueCurrencies = React.useMemo(() => {
  return Array.from(new Set(conceptos.map(c => c.moneda || "PEN")));
}, [conceptos]);

  const handleMonedaSelect = (moneda) => {
    setMonedaSeleccionada(moneda);
    localStorage.setItem("monedaSeleccionada", moneda);
    setAnchorElMoneda(null);
  };
  
  const filteredConceptos = conceptos.filter((concepto) => {
    // Filtrar por concepto (ignora mayúsculas/minúsculas)
    const matchesConcepto = (concepto.concepto || "")
    .toLowerCase()
    .includes((filterValues.concepto || "").toLowerCase());

      
  
    // Filtrar por tipo
    const matchesTipo = filterValues.tipo
      ? concepto.tipo === filterValues.tipo
      : true;

        // --- NUEVA LÓGICA PARA CALCULAR EL NIVEL ---
  // Si 'concepto.categoria' existe y NO es "Sin categoría", entonces:
  //   - Si 'concepto.clasificacion' existe y NO es "No completado", el nivel es "3"
  //   - Si no, el nivel es "2"
  // Si no cumple la condición, el nivel es "1"
  const allowedNivel3 = ["ingresos", "otros ingresos", "costo de ventas (cogs)", "gasto operativo"];
const classification = concepto.clasificacion ? concepto.clasificacion.toLowerCase().trim() : "";
const computedLevel = (concepto.categoria && concepto.categoria !== "Sin categoría")
  ? (allowedNivel3.includes(classification) ? "3" : "2")
  : "1";

const matchesNivel = filterValues.nivel
  ? computedLevel === filterValues.nivel
  : true;
// -------------------------------------------

  
    // Filtrar por fecha desde
    const matchesFechaDesde = filterValues.fechaDesde
      ? new Date(concepto.fecha) >= new Date(filterValues.fechaDesde)
      : true;
  
    // Filtrar por fecha hasta
    const matchesFechaHasta = filterValues.fechaHasta
      ? new Date(concepto.fecha) <= new Date(filterValues.fechaHasta)
      : true;
  
    // Filtrar por monto mínimo
    const matchesMontoMin = filterValues.montoMin
      ? Number(concepto.monto) >= Number(filterValues.montoMin)
      : true;
  
    // Filtrar por monto máximo
    const matchesMontoMax = filterValues.montoMax
      ? Number(concepto.monto) <= Number(filterValues.montoMax)
      : true;
  
    // Filtrar por estado financiero
    const matchesEstadoFinanciero = filterValues.estadoFinanciero
      ? (concepto.nivel === "3" && concepto.clasificacion === filterValues.estadoFinanciero)
      : true;
  
    // Filtrar por categoría
    const matchesCategoria = filterValues.categoria
      ? concepto.categoria === filterValues.categoria
      : true;
  
    // Filtrar por moneda
    const matchesMoneda = filterValues.moneda
      ? concepto.moneda === filterValues.moneda
      : true;
  
    // Filtrar por imagen: "con" = debe tener imágenes; "sin" = no debe tener
    const matchesImagen = filterValues.conImagen === "con"
      ? concepto.imagenes && concepto.imagenes.length > 0
      : filterValues.conImagen === "sin"
        ? !concepto.imagenes || concepto.imagenes.length === 0
        : true;
  
  
    return (
      matchesConcepto &&
      matchesTipo &&
      matchesFechaDesde &&
      matchesFechaHasta &&
      matchesMontoMin &&
      matchesMontoMax &&
      matchesEstadoFinanciero &&
      matchesCategoria &&
      matchesMoneda &&
      matchesImagen &&
      matchesNivel
    );
  });

  const sortedConceptos = React.useMemo(() => {
    let arr = [...filteredConceptos];
    if (orderCriteria.field) {
      arr.sort((a, b) => {
        let valA, valB;
        if (orderCriteria.field === "fecha") {
          valA = new Date(a.fecha).getTime();
          valB = new Date(b.fecha).getTime();
        } else if (orderCriteria.field === "monto") {
          valA = Number(a.monto);
          valB = Number(b.monto);
        } else {
          return 0;
        }
        return orderCriteria.direction === "asc" ? valA - valB : valB - valA;
      });
    }
    return arr;
  }, [filteredConceptos, orderCriteria]);
    
  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="md" sx={{ mt: 4, pb: 5 }}>
        <Paper elevation={3} sx={{ padding: 4, textAlign: "center", mb: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
            <BarChart fontSize="large" color="primary" />
            <Typography variant="h5" gutterBottom sx={{ textTransform: "uppercase" }}>
  {tituloTabla || "MI TABLA FINANCIERA"}
</Typography>

          </Box>
          <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
            Agrega tus conceptos de ingresos y egresos con la flexibilidad que necesites.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutline />}
              onClick={() => {
                setConceptoAEditar(null);
                setModalOpen(true);
              }}
            >
              + NUEVO CONCEPTO
            </Button>
            <Button
  variant="outlined"
  color="info"
  startIcon={<BarChart />}
  onClick={() => navigate("/reportes", {
    state: { idTabla: idTabla }
  })}
  
>
  GENERAR REPORTE 📊
</Button>


            
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<ArrowBack />}
              onClick={() => navigate("/dashboard")}
            >
              VOLVER AL DASHBOARD
            </Button>
          </Box>
        </Paper>

{/* Barra de herramientas agregada */}
<Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    gap: 2,
    mb: 4, // margen inferior para separar de la tabla
    justifyContent: "center"
  }}
>
  <Button
  variant="outlined"
  startIcon={<MonetizationOnIcon />}
  onClick={handleMonedaClick} // Este es el evento que abre el menú
  sx={{
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": { backgroundColor: "#f0f0f0" }
  }}
>
  {monedaSeleccionada} {/* Esto hará que el botón muestre la moneda actual */}
</Button>

<Menu
  anchorEl={anchorElMoneda}
  open={Boolean(anchorElMoneda)}
  onClose={handleMonedaClose}
>
  <MenuItem onClick={() => handleMonedaSelect("PEN")}>PEN - Soles peruanos (S/)</MenuItem>
  <MenuItem onClick={() => handleMonedaSelect("USD")}>USD - Dólar estadounidense ($)</MenuItem>
  <MenuItem onClick={() => handleMonedaSelect("EUR")}>EUR - Euro (€)</MenuItem>
  <MenuItem onClick={() => handleMonedaSelect("CLP")}>CLP - Peso chileno (CLP$)</MenuItem>
  <MenuItem onClick={() => handleMonedaSelect("MXN")}>MXN - Peso mexicano (MX$)</MenuItem>
</Menu>


<Button
  variant="outlined"
  startIcon={<ListIcon />}
  sx={{
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": { backgroundColor: "#f0f0f0" }
  }}
  onClick={() => setModalGestionCategoriasOpen(true)}
>
  Categorías
</Button>


<Button
  variant="outlined"
  startIcon={<VisibilityIcon />}
  sx={{
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": { backgroundColor: "#f0f0f0" }
  }}
  onClick={handleVisualizarClick}
>
  Visualizar
</Button>
<Menu
  anchorEl={anchorElVisualizar}
  open={Boolean(anchorElVisualizar)}
  onClose={handleVisualizarClose}
>
  <MenuItem onClick={() => toggleColumn("fecha")}>
    <Checkbox checked={visibleColumns.fecha} />
    Fecha
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("concepto")}>
    <Checkbox checked={visibleColumns.concepto} />
    Concepto
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("monto")}>
    <Checkbox checked={visibleColumns.monto} />
    Monto
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("tipo")}>
    <Checkbox checked={visibleColumns.tipo} />
    Tipo
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("nivel")}>
    <Checkbox checked={visibleColumns.nivel} />
    Nivel
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("estadoFinanciero")}>
    <Checkbox checked={visibleColumns.estadoFinanciero} />
    Estado Financiero
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("categoria")}>
    <Checkbox checked={visibleColumns.categoria} />
    Categoría
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("moneda")}>
    <Checkbox checked={visibleColumns.moneda} />
    Moneda
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("imagen")}>
    <Checkbox checked={visibleColumns.imagen} />
    Imagen
  </MenuItem>
  <MenuItem onClick={() => toggleColumn("acciones")}>
    <Checkbox checked={visibleColumns.acciones} />
    Acciones
  </MenuItem>
</Menu>


<Button
  variant="outlined"
  startIcon={<TuneIcon />}
  sx={{
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": { backgroundColor: "#f0f0f0" }
  }}
  onClick={() => setOpenFilters(true)}
>
  Filtros
</Button>


<Button
  variant="outlined"
  startIcon={<ArrowDownwardIcon />}
  sx={{
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": { backgroundColor: "#f0f0f0" }
  }}
  onClick={handleOrdenarClick}
>
  Ordenar
</Button>
<Menu
  anchorEl={anchorElOrdenar}
  open={Boolean(anchorElOrdenar)}
  onClose={handleOrdenarClose}
>
  {/* Opciones para ordenar por Fecha */}
  <MenuItem onClick={() => selectOrderCriteria("fecha", "desc")}>
    Fecha: Más reciente → Más antiguo
  </MenuItem>
  <MenuItem onClick={() => selectOrderCriteria("fecha", "asc")}>
    Fecha: Más antiguo → Más reciente
  </MenuItem>
  {/* Opciones para ordenar por Monto */}
  <MenuItem onClick={() => selectOrderCriteria("monto", "desc")}>
    Monto: Mayor → Menor
  </MenuItem>
  <MenuItem onClick={() => selectOrderCriteria("monto", "asc")}>
    Monto: Menor → Mayor
  </MenuItem>
</Menu>


<Button
  variant="outlined"
  startIcon={<MonetizationOnIcon />}
  sx={{
    textTransform: "none",
    fontSize: "0.9rem",
    "&:hover": { backgroundColor: "#f0f0f0" },
    color: balance >= 0 ? "green" : "red"
  }}
  onClick={(event) => {
    // Si hay más de una moneda, se abre un menú; si no, alterna ocultar/mostrar
    if (uniqueCurrencies.length > 1) {
      setAnchorElBalance(event.currentTarget);
    } else {
      setBalanceHidden(!balanceHidden);
    }
  }}
>
  {balanceHidden ? "Balance Oculto" : `💰 ${balanceCurrency} ${balance.toFixed(2)}`}
</Button>
<Menu
  anchorEl={anchorElBalance}
  open={Boolean(anchorElBalance)}
  onClose={() => setAnchorElBalance(null)}
>
  {uniqueCurrencies.map((cur, idx) => (
    <MenuItem key={idx} onClick={() => {
      setBalanceCurrency(cur);
      setBalanceHidden(false);  // Aseguramos que se muestre el balance al seleccionar una moneda
      setAnchorElBalance(null);
    }}>
      {cur} {computeBalance(cur).toFixed(2)}
    </MenuItem>
  ))}
  {balanceHidden ? (
    <MenuItem onClick={() => { setBalanceHidden(false); setAnchorElBalance(null); }}>
      Mostrar Balance
    </MenuItem>
  ) : (
    <MenuItem onClick={() => { setBalanceHidden(true); setAnchorElBalance(null); }}>
      Ocultar Balance
    </MenuItem>
  )}
</Menu>


</Box>



        {/* TABLA DE CONCEPTOS */}
        {loadingConceptos ? (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
    <Typography variant="h4" color="textSecondary">Cargando tabla...</Typography>
  </Box>
) : conceptos.length === 0 ? (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
    <Typography variant="h6" color="textSecondary">
      Ingresa tu primer concepto y ordena tus finanzas.
    </Typography>
  </Box>
) : (
  <TableContainer
    component={Paper}
    sx={{
      minWidth: "900px",
      maxHeight: "80vh",
      overflowX: "auto",
      overflowY: "auto"
    }}
  >
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {visibleColumns.fecha && (
            <TableCell sx={{ minWidth: "130px", whiteSpace: "nowrap", textAlign: "center" }}>
              <b>Fecha</b>
            </TableCell>
          )}
          {visibleColumns.concepto && (
            <TableCell
              sx={{
                minWidth: "350px",
                maxWidth: "500px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflow: "hidden"
              }}
            >
              <b>Concepto</b>
            </TableCell>
          )}
          {visibleColumns.monto && (
            <TableCell sx={{ minWidth: "150px", textAlign: "right" }}>
              <b>Monto</b>
            </TableCell>
          )}
          {visibleColumns.tipo && (
            <TableCell sx={{ minWidth: "100px", textAlign: "center" }}>
              <b>Tipo</b>
            </TableCell>
          )}
          {visibleColumns.nivel && (
            <TableCell sx={{ minWidth: "100px", textAlign: "center" }}>
              <b>Nivel</b>
            </TableCell>
          )}
          {visibleColumns.estadoFinanciero && (
            <TableCell sx={{ minWidth: "150px", textAlign: "center" }}>
              <b>Estado Financiero</b>
            </TableCell>
          )}
          {visibleColumns.categoria && (
            <TableCell
              sx={{
                minWidth: "200px",
                maxWidth: "250px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflow: "hidden"
              }}
            >
              <b>Categoría</b>
            </TableCell>
          )}
          {visibleColumns.moneda && (
            <TableCell sx={{ minWidth: "100px", textAlign: "center" }}>
              <b>Moneda</b>
            </TableCell>
          )}
          {visibleColumns.imagen && (
            <TableCell sx={{ minWidth: "180px", textAlign: "center" }}>
              <b>Imagen</b>
            </TableCell>
          )}
          {visibleColumns.acciones && (
            <TableCell sx={{ minWidth: "120px", textAlign: "center" }}>
              <b>Acciones</b>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedConceptos.map((concepto, index) => (
          <TableRow key={index}>
            {visibleColumns.fecha && (
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                {formatDate(concepto.fecha)}
              </TableCell>
            )}
            {visibleColumns.concepto && (
              <TableCell>{concepto.concepto}</TableCell>
            )}
            {visibleColumns.monto && (
  <TableCell sx={{ textAlign: "right", color: concepto.tipo === "Ingreso" ? "green" : "red" }}>
    {concepto.tipo === "Egreso"
      ? `- ${formatAmount(concepto.monto, concepto.moneda || "PEN")}`
      : formatAmount(concepto.monto, concepto.moneda || "PEN")}
  </TableCell>
)}

            {visibleColumns.tipo && (
              <TableCell sx={{ textAlign: "center" }}>
                {concepto.tipo}
              </TableCell>
            )}
            {visibleColumns.nivel && (
  <TableCell sx={{ textAlign: "center" }}>
    {(() => {
      // Definimos las 4 alternativas que hacen nivel 3, en minúsculas.
      const allowedNivel3 = ["ingresos", "otros ingresos", "costo de ventas (cogs)", "gasto operativo"];
      // Obtenemos la clasificación normalizada (en minúsculas y sin espacios extras)
      const classification = concepto.clasificacion ? concepto.clasificacion.toLowerCase().trim() : "";
      // Si el concepto tiene categoría válida (no es "Sin categoría"), entonces:
      // - Si la clasificación está en allowedNivel3, es Nivel 3; de lo contrario, es Nivel 2.
      // Si no tiene categoría válida, es Nivel 1.
      const computedLevel = (concepto.categoria && concepto.categoria !== "Sin categoría")
        ? (allowedNivel3.includes(classification) ? "3" : "2")
        : "1";
      return `Nivel ${computedLevel}`;
    })()}
  </TableCell>
)}


            {visibleColumns.estadoFinanciero && (
              <TableCell sx={{ textAlign: "center" }}>
                {concepto.nivel === "3" ? concepto.clasificacion : "No completado"}
              </TableCell>
            )}
            {visibleColumns.categoria && (
              <TableCell>{concepto.categoria || "Sin categoría"}</TableCell>
            )}
            {visibleColumns.moneda && (
              <TableCell sx={{ textAlign: "center" }}>
                {concepto.moneda || "PEN"}
              </TableCell>
            )}
            {visibleColumns.imagen && (
  <TableCell sx={{ textAlign: "center" }}>
    {concepto.imagenes && concepto.imagenes.length > 0 ? (
      <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
        {concepto.imagenes.map((img, i) => {
          const src = typeof img === "string" ? img : "";
          return (
            <img
              key={i}
              src={src}
              alt={`Miniatura ${i + 1}`}
              style={{
                maxWidth: "50px",
                maxHeight: "50px",
                cursor: "pointer",
                objectFit: "cover",
                border: "1px solid #ccc"
              }}
              onClick={() => handleImagenClick(concepto.imagenes, i)}
            />
          );
        })}
      </Box>
    ) : (
      "Sin imagen"
    )}
  </TableCell>
)}

            {visibleColumns.acciones && (
              <TableCell sx={{ textAlign: "center" }}>
                <IconButton onClick={() => handleEditarConcepto(concepto)} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleEliminarConcepto(concepto)} color="error">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}


        {/* Espaciador */}
        <Box sx={{ height: 10 }} />
      </Container>

      <Modal
  open={openFilters}
  onClose={() => setOpenFilters(false)}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }}
>
  <Paper
    sx={{
      width: 700,            // Aumenta el ancho a 700px
      p: 3,
      borderRadius: 2,
      position: "relative",
      maxHeight: "80vh",       // Limita la altura máxima
      overflowY: "auto",       // Agrega scrollbar vertical si el contenido sobrepasa la altura
      bgcolor: "white"         // Asegura un fondo blanco para resaltar la "X"
    }}
  >
    {/* Botón de Cierre Mejorado */}
    <IconButton
      onClick={() => setOpenFilters(false)}
      sx={{
        position: "absolute",
        top: 8,
        right: 8,
        opacity: 1,
        color: "black",       // Color negro para mayor contraste
        backgroundColor: "rgba(255,255,255,0.9)"  // Fondo semitransparente para que se distinga
      }}
    >
      <CloseIcon />
    </IconButton>
    <Typography variant="h6" gutterBottom>
      Aplicar Filtros
    </Typography>
    <Grid container spacing={2}>
      {/* Fila 1 */}
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Buscar Concepto"
          value={filterValues.concepto}
          onChange={(e) =>
            setFilterValues({ ...filterValues, concepto: e.target.value })
          }
          margin="normal"
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Tipo</InputLabel>
          <Select
            label="Tipo"
            value={filterValues.tipo}
            onChange={(e) =>
              setFilterValues({ ...filterValues, tipo: e.target.value })
            }
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="Ingreso">Ingreso</MenuItem>
            <MenuItem value="Egreso">Egreso</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ cursor: "pointer" }} onClick={() => document.getElementById("fechaDesdeInput").focus()}>
          <TextField
            id="fechaDesdeInput"
            fullWidth
            label="Fecha Desde"
            type="date"
            value={filterValues.fechaDesde}
            onChange={(e) =>
              setFilterValues({ ...filterValues, fechaDesde: e.target.value })
            }
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Grid>
      {/* Fila 2 */}
      <Grid item xs={4}>
        <Box sx={{ cursor: "pointer" }} onClick={() => document.getElementById("fechaHastaInput").focus()}>
          <TextField
            id="fechaHastaInput"
            fullWidth
            label="Fecha Hasta"
            type="date"
            value={filterValues.fechaHasta}
            onChange={(e) =>
              setFilterValues({ ...filterValues, fechaHasta: e.target.value })
            }
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Monto Mínimo"
          type="number"
          value={filterValues.montoMin}
          onChange={(e) =>
            setFilterValues({ ...filterValues, montoMin: e.target.value })
          }
          margin="normal"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Monto Máximo"
          type="number"
          value={filterValues.montoMax}
          onChange={(e) =>
            setFilterValues({ ...filterValues, montoMax: e.target.value })
          }
          margin="normal"
        />
      </Grid>
      {/* Fila 3 */}
      <Grid item xs={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Estado Financiero</InputLabel>
          <Select
            label="Estado Financiero"
            value={filterValues.estadoFinanciero}
            onChange={(e) =>
              setFilterValues({ ...filterValues, estadoFinanciero: e.target.value })
            }
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="Ingresos">Ingresos</MenuItem>
            <MenuItem value="Otros Ingresos">Otros Ingresos</MenuItem>
            <MenuItem value="COGS">Costo de Ventas (COGS)</MenuItem>
            <MenuItem value="Gasto Operativo">Gasto Operativo</MenuItem>
            <MenuItem value="Sin clasificación">Sin clasificación</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Categoría</InputLabel>
          <Select
            label="Categoría"
            value={filterValues.categoria}
            onChange={(e) =>
              setFilterValues({ ...filterValues, categoria: e.target.value })
            }
          >
            <MenuItem value="">Todas</MenuItem>
            {categorias
  .filter((cat) => !cat.parent)  // Solo las categorías sin padre (principales)
  .map((cat, index) => (
    <MenuItem key={index} value={cat.nombre}>
      {cat.nombre}
    </MenuItem>
  ))}

          </Select>
        </FormControl>
      </Grid>
      {/* Fila 4 */}
      <Grid item xs={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Moneda</InputLabel>
          <Select
            label="Moneda"
            value={filterValues.moneda}
            onChange={(e) =>
              setFilterValues({ ...filterValues, moneda: e.target.value })
            }
          >
            <MenuItem value="">Todas</MenuItem>
            <MenuItem value="PEN">PEN</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="EUR">EUR</MenuItem>
            <MenuItem value="CLP">CLP</MenuItem>
            <MenuItem value="MXN">MXN</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Imagen/no imagen</InputLabel>
          <Select
            label="imagen/no imagen"
            value={filterValues.conImagen}
            onChange={(e) =>
              setFilterValues({ ...filterValues, conImagen: e.target.value })
            }
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="con">Con Imagen</MenuItem>
            <MenuItem value="sin">Sin Imagen</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Nivel</InputLabel>
          <Select
            label="Nivel"
            value={filterValues.nivel}
            onChange={(e) =>
              setFilterValues({ ...filterValues, nivel: e.target.value })
            }
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="1">Nivel 1</MenuItem>
            <MenuItem value="2">Nivel 2</MenuItem>
            <MenuItem value="3">Nivel 3</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
    {/* Botones de acciones */}
    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() =>
          setFilterValues({
            concepto: "",
            tipo: "",
            fechaDesde: "",
            fechaHasta: "",
            montoMin: "",
            montoMax: "",
            estadoFinanciero: "",
            categoria: "",
            moneda: "",
            conImagen: "",
            nivel: "",
          })
        }
      >
        Restablecer Filtros
      </Button>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ ml: 2 }}
        onClick={() => setOpenFilters(false)}
      >
        Aplicar Filtros
      </Button>
    </Box>
  </Paper>
</Modal>


<Modal
  open={imagenModalOpen}
  onClose={() => setImagenModalOpen(false)}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }}
>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80vw",
      maxHeight: "80vh",
      bgcolor: "background.paper",
      p: 2,
      overflow: "auto",
      borderRadius: 2,
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }}
  >
    {/* Botón para cerrar */}
    <IconButton
      aria-label="close"
      onClick={() => setImagenModalOpen(false)}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
    
    {imageCarouselImages.length > 0 && (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            width: "100%",
            justifyContent: "center"
          }}
        >
          <IconButton
            onClick={() =>
              setCurrentImageIndex((prev) =>
                prev > 0 ? prev - 1 : imageCarouselImages.length - 1
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
          <img
            src={imageCarouselImages[currentImageIndex]}
            alt={`Imagen ${currentImageIndex + 1}`}
            style={{
              maxWidth: "60%",
              maxHeight: "60%",
              margin: "0 auto",
              objectFit: "contain"
            }}
          />
          <IconButton
            onClick={() =>
              setCurrentImageIndex((prev) =>
                prev < imageCarouselImages.length - 1 ? prev + 1 : 0
              )
            }
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
        <Typography variant="caption">
          {currentImageIndex + 1} / {imageCarouselImages.length}
        </Typography>
      </>
    )}
  </Box>
</Modal>


      {/* Diálogo de confirmación para eliminación */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas eliminar este concepto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)}>Cancelar</Button>
          <Button onClick={confirmarEliminacion} color="error">Eliminar</Button>
        </DialogActions>
      </Dialog>

      {/* Modal para agregar/editar concepto */}
      <ModalNuevoConcepto
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setConceptoAEditar(null);
        }}
        onGuardarConcepto={async (nuevoConcepto) => {
          try {
            // Preparamos el objeto, añadiéndole la moneda seleccionada
            let conceptoConMoneda = { ...nuevoConcepto, moneda: monedaSeleccionada };
            // Calculamos el nivel usando la función computeNivel
            const nivelCalculado = computeNivel(conceptoConMoneda);
            // Agregamos el campo 'nivel' al objeto
            conceptoConMoneda = { ...conceptoConMoneda, nivel: nivelCalculado };
        
            if (conceptoAEditar) {
              // Si es una edición, actualizamos en Firestore
              await actualizarConcepto(idTabla, conceptoAEditar.id, conceptoConMoneda);
              setConceptos((prev) =>
                prev.map((c) => (c.id === conceptoAEditar.id ? { ...c, ...conceptoConMoneda } : c))
              );
            } else {
              // Si es un nuevo concepto, lo agregamos en Firestore
              const conceptoId = await agregarConcepto(idTabla, conceptoConMoneda);
              setConceptos((prev) => [...prev, { id: conceptoId, ...conceptoConMoneda }]);
            }
          } catch (error) {
            console.error("❌ Error al guardar el concepto en Firestore:", error);
          }
        }}
        
        
        categorias={categorias}
        setCategorias={setCategorias}
        idTabla={idTabla}
        // Se pasa el concepto a editar (si existe) para precargar datos
        conceptoAEditar={conceptoAEditar}
        monedaSeleccionada={monedaSeleccionada}
      />

      {/* Modal para gestionar categorías */}
      <ModalGestionCategorias
  open={modalGestionCategoriasOpen}
  onClose={() => setModalGestionCategoriasOpen(false)}
  categorias={categorias}
  onGuardar={async (callback, categoriaEditadaOriginal, nuevaCategoriaEditada) => {
    try {
      // Actualiza las categorías globales
      setCategorias((prev) => {
        const nuevasCategorias = typeof callback === "function" ? callback(prev) : prev;
        // Envolver el array en un objeto para que Firestore lo acepte
        guardarCategoria(idTabla, nuevasCategorias);
        return nuevasCategorias;
      });
      
      // Si se editó una categoría, actualizar todos los conceptos que la tenían asignada
      if (categoriaEditadaOriginal && nuevaCategoriaEditada) {
        const nuevosConceptos = await Promise.all(
          conceptos.map(async (concepto) => {
            if (concepto.categoria === categoriaEditadaOriginal.nombre) {
              const conceptoActualizado = { ...concepto, categoria: nuevaCategoriaEditada.nombre };
              try {
                await actualizarConcepto(idTabla, concepto.id, conceptoActualizado);
              } catch (error) {
                console.error("❌ Error al actualizar en Firestore:", error);
              }
              return conceptoActualizado;
            }
            return concepto;
          })
        );
        setConceptos(nuevosConceptos);
      }
    } catch (error) {
      console.error("❌ Error en onGuardar de ModalGestionCategorias:", error);
    }
  }}
  
  
  onEliminar={async (categoriaAEliminar) => {
    try {
      // Actualizar las categorías: filtrar la categoría a eliminar y guardarla
      setCategorias((prev) => {
        const nuevasCategorias = prev.filter((cat) => cat.nombre !== categoriaAEliminar.nombre);
        guardarCategoria(idTabla, nuevasCategorias);
        return nuevasCategorias;
      });
      console.log(`✅ Categoría "${categoriaAEliminar.nombre}" eliminada en Firestore.`);
    } catch (error) {
      console.error("❌ Error eliminando categoría en Firestore:", error);
    }
    
    try {
      // Actualizar los conceptos: si tenían la categoría eliminada, se les asigna "Sin categoría"
      const nuevosConceptos = await Promise.all(
        conceptos.map(async (concepto) => {
          if (concepto.categoria === categoriaAEliminar.nombre) {
            const conceptoActualizado = { ...concepto, categoria: "Sin categoría" };
            try {
              await actualizarConcepto(idTabla, concepto.id, conceptoActualizado);
            } catch (error) {
              console.error("❌ Error al actualizar concepto en Firestore:", error);
            }
            return conceptoActualizado;
          }
          return concepto;
        })
      );
      setConceptos(nuevosConceptos);
    } catch (error) {
      console.error("❌ Error actualizando conceptos tras eliminación:", error);
    }
  }}
  
/>
    </>
  );
}

export default TablaFinanciera;
