import React, { useState } from "react";
import api from "./api.js";
import { login } from "./api.js"; 
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Alert,
} from "@mui/material";

import SuperAdminPanel from "./pages/SuperAdminPanel.js";
import SolicitudAmpliacion from "./pages/SolicitudAmpliacion.js";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import WizardForm from "./pages/WizardForm.js"; // Importamos el Wizard
import Dashboard from "./pages/Dashboard.js"; // Importamos el Dashboard
import TablaFinanciera from "./pages/TablaFinanciera.js";
import Reportes from "./pages/Reportes.js";
import ReporteGenerado from "./pages/ReporteGenerado.js";
import { getAuth, sendPasswordResetEmail, signInWithCustomToken, signInWithPopup, GoogleAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "./firebase/firebase.js";
import SolicitudRecompensas from "./pages/SolicitudRecompensas.js";
import Configuracion from "./pages/Configuracion.js";
import Tutoriales from "./pages/Tutoriales.js";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nombre, setNombre] = useState("");
  const [modo, setModo] = useState("login"); // Alternar entre Login y Registro
  const [mensaje, setMensaje] = useState("");
  const [emailRecuperacion, setEmailRecuperacion] = useState("");
  const [mostrarRecuperacion, setMostrarRecuperacion] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingRegistro, setLoadingRegistro] = useState(false);
  const [loadingRecuperacion, setLoadingRecuperacion] = useState(false);
  const [openRecuperacionModal, setOpenRecuperacionModal] = useState(false);
  const [emailReset, setEmailReset] = useState("");

  const handleGoogleLogin = async () => {
    try {
      const auth = getAuth();
      // Configura la persistencia para que la sesión se mantenga en el navegador
      await setPersistence(auth, browserLocalPersistence);
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // Obtén o crea el documento del usuario en Firestore
      const uid = user.uid;
      const usuariosRef = doc(db, "usuarios", uid);
      const usuarioDoc = await getDoc(usuariosRef);
      if (!usuarioDoc.exists()) {
        // Generamos un ID informativo de 7 dígitos (esto se usará solo para mostrar)
        function generarIDUnico() {
          return Math.floor(Math.random() * 9000000) + 1000000;
        }
        const nuevoId = generarIDUnico();
        const fechaRegistro = new Date().toISOString();
        await setDoc(usuariosRef, {
          uid: uid, // Guardamos el UID real de Firebase
          id: nuevoId, // ID informativo
          nombre: user.displayName || "Usuario",
          email: user.email,
          fechaRegistro,
          creditos: 7,
          messages: [] // Iniciamos el campo messages como un array vacío
        });
        console.log("Documento de usuario creado en Firestore con créditos iniciales y campo messages vacío.");
      } else {
        console.log("El documento de usuario ya existe en Firestore.");
      }
      
      console.log("Usuario autenticado con Google:", user);
      window.location.href = "/wizard";
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error.message);
    }
  };
  
  
  const manejarSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit ejecutado, modo:", modo);
    setMensaje("");
    
    // Activa el estado de loading según el modo
    if (modo === "login") {
      setLoadingLogin(true);
    } else {
      setLoadingRegistro(true);
    }
    
    try {
      if (modo === "registro" && password !== confirmPassword) {
        setMensaje("Las contraseñas no coinciden.");
        return;
      }
    
      const endpoint = modo === "login" ? "/login" : "/registro";
      const datos = modo === "login" ? { email, password } : { nombre, email, password, confirmPassword };
    
      const respuesta = modo === "login" 
      ? await login(email, password)  // Llamamos a la función login() en vez de api.post()
      : await api.post("/registro", { nombre, email, password, confirmPassword });
        
      if (modo === "login") {
        try {
          const auth = getAuth();
          console.log("Token recibido del backend:", respuesta.token);
          // Configura la persistencia para que la sesión se mantenga en el navegador
          await setPersistence(auth, browserLocalPersistence);
          // Luego, autentica con Firebase usando el token personalizado
          await signInWithCustomToken(auth, respuesta.token);
          console.log("Autenticación con Firebase exitosa.");
          window.location.href = "/wizard"; // Redirigir al Wizard
        } catch (error) {
          console.error("Error al iniciar sesión con Firebase:", error);
        }
      } else {
        setMensaje("Registro exitoso. Ahora inicia sesión.");
        setModo("login");
      }
    } catch (error) {
      setMensaje(error.response?.data?.mensaje || "Error en la solicitud.");
    } finally {
      if (modo === "login") {
        setLoadingLogin(false);
      } else {
        setLoadingRegistro(false);
      }
    }
  };
  

  const manejarRecuperacion = async (e) => {
    e.preventDefault();
    setMensaje("");

    try {
      await api.post("/recuperar-password", { email: emailRecuperacion });
      setMensaje(
        "Si el correo existe, recibirás un link para recuperar tu contraseña."
      );
    } catch (error) {
      setMensaje("Error en la solicitud.");
    }
};

const handleRecuperarPassword = async () => {
  const auth = getAuth();
  if (!emailReset) {
    alert("Por favor, ingresa tu correo electrónico.");
    return;
  }
  try {
    await sendPasswordResetEmail(auth, emailReset);
    alert("Se ha enviado un enlace de restablecimiento de contraseña a tu correo.");
  } catch (error) {
    console.error("Error al enviar el correo de recuperación:", error);
    alert("Error al enviar el enlace. Verifica que el correo sea correcto.");
  }
};

  return (
    <>
    <Container component="main" maxWidth="xs">
      <Paper elevation={6} sx={{ padding: 3, marginTop: 8 }}>
        {mostrarRecuperacion ? (
          <>
            <Typography variant="h5" gutterBottom>Recuperar Contraseña</Typography>
            <form onSubmit={manejarRecuperacion}>
              <TextField
                fullWidth
                type="email"
                label="Correo Electrónico"
                value={emailRecuperacion}
                onChange={(e) => setEmailRecuperacion(e.target.value)}
                required
                margin="normal"
              />
              <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>
                Enviar Link
              </Button>

              <Button fullWidth variant="outlined" sx={{ mt: 2 }} onClick={() => setMostrarRecuperacion(false)}>
                Volver
              </Button>
            </form>
          </>
        ) : (
          <>
<img src="/logo.png" alt="Logo de la empresa" 
  style={{ display: "block", margin: "0 auto 30px", width: "250px", height: "auto" }} 
/>
            <Typography variant="h5" gutterBottom>{modo === "login" ? "Iniciar Sesión" : "Registrarse"}</Typography>
            <form onSubmit={manejarSubmit}>
              {modo === "registro" && (
                <TextField
                  fullWidth
                  label="Nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  required
                  margin="normal"
                />
              )}
              <TextField
                fullWidth
                type="email"
                label="Correo Electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                margin="normal"
              />
              <TextField
                fullWidth
                type="password"
                label="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                margin="normal"
              />
              {modo === "registro" && (
                <TextField
                  fullWidth
                  type="password"
                  label="Confirmar Contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  margin="normal"
                />
              )}
              {mensaje && (
                <Alert severity="success" sx={{ mt: 2 }}>{mensaje}</Alert>
              )}
              <Button
  type="submit"
  fullWidth
  variant="contained"
  color="primary"
  sx={{ mt: 2 }}
  disabled={loadingLogin}
>
  {modo === "login" 
    ? (loadingLogin ? "Iniciando..." : "Iniciar Sesión")
    : (loadingRegistro ? "Registrando..." : "Registrarse")}
</Button>

              <Button 
  fullWidth 
  variant="outlined" 
  color="secondary" 
  sx={{ 
    mt: 2, 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    gap: 1, 
    border: "1px solid #4285F4", 
    color: "#4285F4", 
    fontWeight: "bold" 
  }} 
  onClick={handleGoogleLogin}
>
  <img 
    src="https://developers.google.com/identity/images/g-logo.png" 
    alt="Google Logo" 
    style={{ width: "24px", height: "24px" }} 
  />
  Iniciar sesión con Google
</Button>

            </form>



            <Grid container spacing={2} sx={{ mt: 2 }}>
  <Grid item xs={6}>
    <Button fullWidth variant="outlined" onClick={() => setModo(modo === "login" ? "registro" : "login")}>
      {modo === "login" ? "Crear cuenta" : "Ya tengo cuenta"}
    </Button>
  </Grid>
  <Grid item xs={6}>
  {modo === "login" && (
  <Button fullWidth variant="text" color="primary" onClick={() => setOpenRecuperacionModal(true)}>
    Olvidé mi contraseña
  </Button>
)}


  </Grid>
</Grid>

          </>
        )}
      </Paper>
    </Container>
    <Dialog
  open={openRecuperacionModal}
  onClose={() => setOpenRecuperacionModal(false)}
  fullWidth
  maxWidth="sm"
  PaperProps={{
    sx: { borderRadius: 2, p: 0 }
  }}
>
  <DialogTitle sx={{ backgroundColor: "#1976d2", color: "#fff", py: 2, px: 3 }}>
    Recuperar Contraseña
  </DialogTitle>
  <DialogContent sx={{ p: 3 }}>
    <Typography variant="body1" sx={{ mb: 2 }}>
      Ingresa el correo con el que te registraste para que te enviemos un enlace de restablecimiento.
    </Typography>
    <TextField
      fullWidth
      type="email"
      label="Correo Electrónico"
      value={emailReset}
      onChange={(e) => setEmailReset(e.target.value)}
      margin="normal"
      variant="outlined"
    />
  </DialogContent>
  <DialogActions sx={{ p: 2, justifyContent: "flex-end" }}>
    <Button onClick={() => setOpenRecuperacionModal(false)} variant="outlined" color="secondary">
      Cancelar
    </Button>
    <Button onClick={handleRecuperarPassword} variant="contained" color="primary">
      Enviar Enlace
    </Button>
  </DialogActions>
</Dialog>


  </>
);
}

function App() {
  return (
    <Router>
      <Routes>
  <Route path="/" element={<Login />} />
  <Route path="/wizard" element={<WizardForm />} />
  <Route path="/dashboard" element={<Dashboard />} />
  <Route path="/tabla/:idTabla" element={<TablaFinanciera />} />
  <Route path="/reportes" element={<Reportes />} />
  <Route path="/reporte-generado" element={<ReporteGenerado />} />
  <Route path="/superadmin" element={<SuperAdminPanel />} />
  <Route path="/solicitar-creditos" element={<SolicitudAmpliacion />} />
  <Route path="/solicitar-recompensas" element={<SolicitudRecompensas />} />
  <Route path="/configuracion" element={<Configuracion />} />
  <Route path="/tutoriales" element={<Tutoriales />} />
  <Route path="*" element={<Navigate to="/" />} />
</Routes>
    </Router>
  );
}

export default App;
