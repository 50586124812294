// Importamos solo una vez las funciones necesarias de Firestore
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDSXspO89_SP-ZegwQZ_OXq-5mG_R2PbXw",
  authDomain: "mi-proyecto-financiero.firebaseapp.com",
  projectId: "mi-proyecto-financiero",
  storageBucket: "mi-proyecto-financiero.firebasestorage.app",
  messagingSenderId: "1013051267138",
  appId: "1:1013051267138:web:fb1acfd4ec453f7c5375db",
  measurementId: "G-P47Z2ESY4S"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
