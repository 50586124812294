import React, { useState, useEffect } from "react";
import axios from "../api.js";
import { useNavigate } from "react-router-dom";
import Edit from '@mui/icons-material/Edit';
import { 
  Container, 
  Button, 
  Typography,
  Alert, 
  Paper, 
  Grid, 
  Card, 
  CardContent, 
  Modal, 
  Box, 
  TextField, 
  Input, 
  IconButton 
} from "@mui/material";

import { AddCircleOutline, BarChart, Settings, Close } from "@mui/icons-material"; // 📌 AGREGADO
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { obtenerTablasFinancieras } from "../firebase/firebaseService.js"; // Importa la función para Firestore
import { guardarTabla } from "../firebase/firebaseService.js"; // Importa la función de Firestore
import { eliminarTabla } from "../firebase/firebaseService.js"; // Importa la función de Firestore
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Navbar from "../components/Navbar.js";
import { doc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase.js";

function Dashboard() {
  // Estados generales
  const [categorias, setCategorias] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [massMessage, setMassMessage] = useState(null);
const [massPopupOpen, setMassPopupOpen] = useState(false);
const [dismissedMassMessageDate, setDismissedMassMessageDate] = useState(
  localStorage.getItem("dismissedMassMessageDate") || null
);

const [creditWarningOpen, setCreditWarningOpen] = useState(false);


useEffect(() => {
  // Creamos una referencia al documento "lastMassMessage" en la colección "massMessages"
  const massMessageRef = doc(db, "massMessages", "lastMassMessage");
  const unsubscribe = onSnapshot(
    massMessageRef,
    (docSnap) => {
      if (docSnap.exists()) {
        const newMassMessage = docSnap.data();
        // Solo abrimos el pop-up si NO hay un mensaje cerrado o si la fecha del nuevo mensaje es diferente
        if (!dismissedMassMessageDate || newMassMessage.fecha !== dismissedMassMessageDate) {
          setMassMessage(newMassMessage);
          setMassPopupOpen(true);
        }
      } else {
        setMassMessage(null);
        setMassPopupOpen(false);
      }
    },
    (error) => {
      console.error("Error al obtener el mensaje masivo:", error);
    }
  );
  return () => unsubscribe();
}, [dismissedMassMessageDate]);


  const [tablasFinancieras, setTablasFinancieras] = useState([]);

  // Estado para cargar las tablas (usaremos "loadingTables")
  const [loadingTables, setLoadingTables] = useState(true);

  const cargarTablas = async () => {
    try {
      console.log("📡 Cargando tablas desde Firestore...");
      // Asegúrate de tener el UID del usuario actual, por ejemplo, userData.uid o firebaseUser.uid
      const tablas = await obtenerTablasFinancieras(userData.uid);
      console.log("✅ Tablas cargadas:", tablas);
      setTablasFinancieras(tablas);
    } catch (error) {
      console.error("❌ Error cargando tablas:", error);
    } finally {
      setLoadingTables(false);
    }
  };
  

  useEffect(() => {
    console.log("Estado actual de tablasFinancieras:", tablasFinancieras);
  }, [tablasFinancieras]);

  // Estados de carga, modal, formulario, etc.
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [newTable, setNewTable] = useState({
    title: "",
    description: "",
    image: "", // Cadena vacía para evitar errores
  });
  const [saving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTable, setEditingTable] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [descriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState(null);

  // Cargar datos del usuario
  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        // Creamos la referencia al documento del usuario en Firestore
        const userDocRef = doc(db, "usuarios", firebaseUser.uid);
        const unsubscribeUser = onSnapshot(
          userDocRef,
          (docSnap) => {
            if (docSnap.exists()) {
              setUserData({ ...docSnap.data(), uid: firebaseUser.uid });
            } else {
              console.error("No se encontró el documento de usuario en Firestore.");
            }
            setLoading(false);
          },
          (error) => {
            console.error("Error al escuchar el documento del usuario:", error);
            setLoading(false);
          }
        );
        // Regresamos la función de limpieza para el listener del documento del usuario
        return () => {
          unsubscribeUser();
        };
      } else {
        navigate("/login");
        setLoading(false);
      }
    });
    return unsubscribeAuth;
  }, [navigate]);
  
  
  useEffect(() => {
    if (userData) {
      cargarTablas();
    }
  }, [userData]);
  
  useEffect(() => {
    const actualizarCreditos = async () => {
      if (userData && userData.lastUpdate && userData.creditos !== undefined) {
        // Convertimos la última actualización a un objeto Date
        const lastUpdate = new Date(userData.lastUpdate);
        const hoy = new Date();
        
        // Convertimos ambas fechas a cadenas con formato de solo fecha (sin hora)
        const fechaLast = lastUpdate.toDateString();
        const fechaHoy = hoy.toDateString();
  
        // Si la fecha de hoy es diferente a la fecha de la última actualización,
        // se calcula la diferencia en días completos.
        if (fechaHoy !== fechaLast) {
          // Calcula la diferencia en milisegundos y conviértela a días completos
          const diasTranscurridos = Math.floor((hoy - lastUpdate) / (1000 * 60 * 60 * 24));
          
          if (diasTranscurridos > 0) {
            // Resta los días transcurridos a los créditos, sin bajar de 0
            const nuevosCreditos = Math.max(userData.creditos - diasTranscurridos, 0);
            const updatedLastUpdate = hoy.toISOString();
            
            // Actualiza el documento en Firestore
            const docRef = doc(db, "usuarios", userData.uid);
            await updateDoc(docRef, {
              creditos: nuevosCreditos,
              lastUpdate: updatedLastUpdate,
            });
            
            // Actualiza el estado local para reflejar el cambio
            setUserData({
              ...userData,
              creditos: nuevosCreditos,
              lastUpdate: updatedLastUpdate,
            });
          }
        }
      }
    };
  
    actualizarCreditos();
  }, [userData]);
  
  
  // Funciones para abrir y cerrar modal
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  // Manejo del formulario
  const handleInputChange = (event) => {
    setNewTable({ ...newTable, [event.target.name]: event.target.value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Obtiene el primer archivo seleccionado
    if (file) {
      const reader = new FileReader(); // Crea un lector de archivos
      reader.readAsDataURL(file); // Convierte la imagen a Base64
      reader.onloadend = () => {
        setNewTable((prev) => ({
          ...prev,
          image: reader.result, // Guarda la imagen en Base64 en el estado
        }));
      };
    }
  };

  // Función para crear o editar tabla
  const handleCreateOrEditTable = async () => {
    if (!newTable.title.trim()) {
      alert("El título es obligatorio");
      return;
    }

    // Activamos el indicador de carga
    setSaving(true);

    try {
      if (isEditing) {
        // Si es edición, actualizamos la tabla existente en la subcolección del usuario
        await guardarTabla(editingTable.id, {
          titulo: newTable.title.trim(),
          descripcion: newTable.description.trim() || editingTable.descripcion,
          image: newTable.image || editingTable.image,
          fechaCreacion: editingTable.fechaCreacion, // Conservamos la fecha original
        }, userData.uid);
        
        const updatedTables = tablasFinancieras.map((tabla) =>
          tabla.id === editingTable.id
            ? {
                ...tabla,
                titulo: newTable.title.trim(),
                descripcion: newTable.description.trim() || editingTable.descripcion,
                image: newTable.image || editingTable.image,
                fechaCreacion: editingTable.fechaCreacion  // Conservamos la fecha original
              }
            : tabla
        );
        setTablasFinancieras(updatedTables);
      } else {
        // Si es creación, creamos una nueva tabla
        const nuevaTabla = {
          titulo: newTable.title.trim() || "Nueva Tabla",
          descripcion: newTable.description.trim() || "Sin descripción",
          image: newTable.image,
          fechaCreacion: new Date().toISOString(),
        };
      
        const nuevaTablaId = await guardarTabla(null, nuevaTabla, userData.uid);
        console.log("Creando nueva tabla con datos:", nuevaTabla);
        nuevaTabla.id = nuevaTablaId;
        setTablasFinancieras([...tablasFinancieras, nuevaTabla]);
      
        navigate("/tabla/" + encodeURIComponent(nuevaTabla.id));
      }
      

      setModalOpen(false);
    } catch (error) {
      console.error("❌ Error al guardar tabla:", error);
    } finally {
      // Desactivamos el indicador de carga sin importar el resultado
      setSaving(false);
    }
  };

  // Si todavía estamos cargando datos del usuario, mostramos un indicador
  if (loading || !userData) {
    return (
      <Container component="main" maxWidth="md">
        <Typography variant="h5" align="center">Cargando...</Typography>
      </Container>
    );
  }
  

  // Función para eliminar tabla
  const handleEliminarTabla = async (event, id) => {
    event.stopPropagation();
    if (window.confirm("¿Estás seguro de que deseas eliminar esta tabla? Esta acción no se puede deshacer.")) {
      try {
        await eliminarTabla(userData.uid, id);
        setTablasFinancieras(tablasFinancieras.filter(tabla => tabla.id !== id));
      } catch (error) {
        console.error("❌ Error al eliminar tabla:", error);
      }
    }
  };

  const auth = getAuth();
  const firebaseUser = auth.currentUser;
  const nombreMostrado = (userData?.nombre || (firebaseUser && firebaseUser.displayName ? firebaseUser.displayName : "") || "Usuario").split(" ")[0];

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="lg" sx={{ mt: 4, pb: 35 }}>
        <Grid container spacing={3}>
          {/* CABECERA: Bienvenida y botón de donar */}
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 4, position: "relative" }}>
            <Typography variant="h4" gutterBottom sx={{ color: "#1976d2", textAlign: "center" }}>
  {userData?.gender === "Femenino"
    ? "Hola, "
    : userData?.gender === "Prefiero no decirlo"
    ? "Hola, "
    : "Hola, "}{" "}
  {nombreMostrado} 🎉
</Typography>
<Typography variant="body2" sx={{ fontWeight: "bold", color: "#DAA520" }}>
    ID: {userData?.id || "N/A"}
  </Typography>

<Typography variant="body1" sx={{ marginTop: 2 }}>
  El hecho de que estés en esta plataforma demuestra que eres una persona inteligente y precavida, ¡capaz de hacer que las cosas sucedan! 🚀
</Typography>

<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 2, mt: 1 }}>
  {/* Texto dinámico: aquí usaremos una variable 'dias' para el número de días restantes.
      Por ejemplo, usamos 7 de ejemplo. Luego se agregará la condición de color */}
  <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 1 }}>
  <Typography variant="body1" sx={{ fontWeight: "bold", color: (userData?.creditos || 0) < 10 ? "red" : "blue" }}>
    Te quedan {userData?.creditos || 0} días de uso de la plataforma
  </Typography>
</Box>
<Button variant="contained" color="primary" onClick={() => navigate("/solicitar-creditos")}>
  Ampliar
</Button>

</Box>

            </Paper>
          </Grid>

          {/* CABECERA DE LAS TABLAS */}
<Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
  <Typography variant="h6" gutterBottom>
    Mis Tablas Financieras 📊
  </Typography>
  <Box>
  <Button
  variant="contained"
  color="primary"
  sx={{ mr: 2, mb: { xs: 2, md: 0 } }}
  onClick={() => {
    if (userData && userData.creditos <= 0) {
      setCreditWarningOpen(true);
    } else {
      setIsEditing(false);
      setNewTable({ title: "", description: "", image: null });
      setModalOpen(true);
    }
  }}
>
  + Crear Nueva Tabla
</Button>

    <Button
      variant="contained"
      sx={{
        backgroundColor: "#FF5722",
        color: "white",
        "&:hover": { backgroundColor: "#E64A19" }
      }}
      onClick={() => navigate("/solicitar-recompensas")}
    >
      🔥CONSIGUE HASTA 6 MESES MÁS GRATIS 🔥
    </Button>
  </Box>
</Grid>


          {/* SECCIÓN DE LAS TABLAS */}
          <Grid item xs={12}>
            <Grid 
              container 
              spacing={3} 
              sx={{ 
                justifyContent: "flex-start", 
                alignItems: "flex-start",
                flexWrap: "wrap"
              }}
            >
              { (saving || loadingTables) ? (
                <Typography variant="body1">Cargando...</Typography>
              ) : (
                tablasFinancieras.length > 0 ? (
                  tablasFinancieras.map((tabla) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ display: "flex", justifyContent: "center" }}>
                      <Card
                        sx={{
                          width: "800px",
                          height: "160px",
                          display: "flex",
                          flexDirection: "row",
                          padding: "16px",
                          position: "relative",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)"
                          }
                        }}
                        onClick={() => {
                          if (userData && userData.creditos <= 0) {
                            // Si el usuario no tiene días, se muestra el modal de aviso
                            setCreditWarningOpen(true);
                          } else {
                            // Si tiene días, se navega normalmente a la tabla
                            navigate(`/tabla/${encodeURIComponent(tabla.id)}`, {
                              state: { tituloTabla: tabla.titulo }
                            });
                          }
                        }}
                        

                      >
                        {/* Íconos de Editar y Eliminar */}
                        <Box sx={{ display: "flex", gap: 1, position: "absolute", top: 8, right: 8 }}>
                          <IconButton
                            sx={{
                              cursor: "pointer",
                              padding: 1.5,
                              minWidth: "40px",
                              minHeight: "40px",
                              zIndex: 10,
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                              },
                            }}
                            color="primary"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsEditing(true);
                              setEditingTable(tabla);
                              setNewTable({
                                title: tabla.titulo,
                                description: tabla.descripcion,
                                image: tabla.image || null,
                              });
                              setModalOpen(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </IconButton>

                          <IconButton
                            sx={{
                              cursor: "pointer",
                              padding: 1.5,
                              minWidth: "40px",
                              minHeight: "40px",
                              zIndex: 10,
                              transition: "background-color 0.3s ease",
                              "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.2)",
                              },
                            }}
                            color="error"
                            size="small"
                            onClick={(e) => handleEliminarTabla(e, tabla.id)}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>

                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 0,
                            height: "100%"
                          }}
                        >
                          <Box
                            sx={{
                              width: "120px",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "8px"
                            }}
                          >
                            <img
                              src={tabla.image || "/images/chanchit.png"}
                              alt="Imagen de la tabla"
                              onClick={(e) => {
                                e.stopPropagation(); 
                                setImageModalUrl(tabla.image || "/images/chanchit.png");
                                setImageModalOpen(true);
                              }}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "8px 0 0 8px",
                              }}
                            />
                          </Box>

                          {/* TEXTO A LA DERECHA */}
                          <Box
                            sx={{
                              flex: 1,
                              padding: "16px",
                              paddingRight: "80px",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column"
                            }}
                          >
                            {/* Título y Descripción */}
                            <Box>
                              <Typography
                                variant="h6"
                                sx={{
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden"
                                }}
                              >
                                {tabla.titulo}
                              </Typography>

                              <Typography variant="body2" color="textSecondary">
                                {tabla.descripcion.length > 70 ? (
                                  <>
                                    {tabla.descripcion.substring(0, 70)}...
                                    <Typography
                                      component="span"
                                      color="primary"
                                      sx={{ cursor: "pointer", fontWeight: "bold" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedDescription(tabla.descripcion);
                                        setDescriptionModalOpen(true);
                                      }}
                                    >
                                      más
                                    </Typography>
                                  </>
                                ) : (
                                  tabla.descripcion
                                )}
                              </Typography>
                            </Box>

                            {/* Fecha */}
                            <Typography
                              variant="caption"
                              sx={{
                                mt: "auto",
                                alignSelf: "flex-end",
                                color: "#4CAF50",
                                fontWeight: "bold"
                              }}
                            >
                              Creado el: {tabla.fechaCreacion ? new Date(tabla.fechaCreacion).toLocaleDateString("es-ES") : "Fecha no disponible"}
                            </Typography>
                          </Box>

                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1">No hay tablas creadas aún.</Typography>
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Dialog
  open={creditWarningOpen}
  onClose={() => setCreditWarningOpen(false)}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>Sin Días Disponibles</DialogTitle>
  <DialogContent>
    <Typography>
      No cuenta con días de uso de la plataforma, puede ampliar su membresía por menos de $1 al mes, ¡Aprovecha la promoción y sigue controlando tu salud financiera! Puedes pagar con YAPE o MERCADOPAGO.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button variant="contained" color="primary" onClick={() => navigate("/solicitar-creditos")}>
      Ampliar
    </Button>
    <Button variant="outlined" color="secondary" onClick={() => setCreditWarningOpen(false)}>
      Cerrar
    </Button>
  </DialogActions>
</Dialog>


      <Dialog
  open={massPopupOpen}
  onClose={() => setMassPopupOpen(false)}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>
    Aviso del Administrador
  </DialogTitle>
  <DialogContent>
    <Alert severity={massMessage && massMessage.severity ? massMessage.severity : "info"}>
      {massMessage ? massMessage.mensaje : ""}
      
      {/* Si hay imagen, la mostramos */}
      {massMessage && massMessage.imagenURL && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <img 
            src={massMessage.imagenURL} 
            alt="Mensaje del Administrador" 
            style={{ maxWidth: "100%", borderRadius: 4 }} 
          />
        </Box>
      )}
      
      <br />
      {massMessage && (
        <Typography variant="caption">
          {new Date(massMessage.fecha).toLocaleString()}
        </Typography>
      )}
    </Alert>
  </DialogContent>
  <DialogActions>
    {/* Si se definió texto y link para el botón, se muestra el botón */}
    {massMessage && massMessage.botonTexto && massMessage.botonTexto.trim() !== "" && massMessage.botonLink && (
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => window.open(massMessage.botonLink, "_blank")}
      >
        {massMessage.botonTexto}
      </Button>
    )}
    <Button
  onClick={() => {
    setMassPopupOpen(false);
    if (massMessage) {
      setDismissedMassMessageDate(massMessage.fecha);
      localStorage.setItem("dismissedMassMessageDate", massMessage.fecha);
    }
  }}
  color="primary"
>
  Cerrar
</Button>


  </DialogActions>
</Dialog>



      {/* Modal para Crear Nueva Tabla */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box 
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {/* Botón para cerrar el modal */}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          {/* Si hay imagen seleccionada, se muestra a la izquierda */}
          {selectedImage && (
            <Box 
              sx={{
                flex: "0 0 40%", 
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img 
                src={selectedImage} 
                alt="Imagen de la tabla" 
                style={{ maxWidth: "100%", maxHeight: "250px", borderRadius: "8px" }} 
              />
            </Box>
          )}

          <Typography variant="h6" gutterBottom>
            Descripción Completa
          </Typography>

          <TextField
            fullWidth
            label="Título"
            name="title"
            value={newTable.title}
            onChange={(event) => {
              const value = event.target.value;
              if (value.length <= 40) {
                setNewTable({ ...newTable, title: value });
              }
            }}
            margin="normal"
            required
            helperText={`${40 - newTable.title.length} caracteres restantes`}
          />

          <TextField
            fullWidth
            label="Descripción"
            name="description"
            value={newTable.description}
            onChange={(event) => {
              const value = event.target.value;
              if (value.length <= 500) {
                setNewTable({ ...newTable, description: value });
              }
            }}
            margin="normal"
            multiline
            rows={2}
            helperText={`${500 - newTable.description.length} caracteres restantes`}
          />

          <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
            Adjuntar Imagen (Opcional)
          </Typography>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            fullWidth
          />

          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
            onClick={handleCreateOrEditTable}
            disabled={saving}
          >
            {saving ? "Guardando..." : (isEditing ? "Guardar" : "Crear")}
          </Button>
        </Box>
      </Modal>

      {/* Modal para mostrar la descripción completa */}
      <Modal open={descriptionModalOpen} onClose={() => setDescriptionModalOpen(false)}>
        <Box 
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setDescriptionModalOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <Typography variant="h6" gutterBottom>
            Descripción Completa
          </Typography>

          <Typography variant="body1">
            {selectedDescription}
          </Typography>

          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setDescriptionModalOpen(false)}
          >
            OK
          </Button>
        </Box>
      </Modal>

      {/* Modal para mostrar la imagen completa */}
      <Modal open={imageModalOpen} onClose={() => setImageModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80vw",
            maxHeight: "80vh",
            bgcolor: "background.paper",
            p: 2,
            overflow: "auto",
            borderRadius: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setImageModalOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <img
            src={imageModalUrl}
            alt="Imagen completa"
            style={{
              maxWidth: "60%",
              maxHeight: "60%",
              width: "auto",
              height: "auto",
              display: "block",
              margin: "0 auto"
            }}
          />
        </Box>
      </Modal>
    </>
  );
}

export default Dashboard;
