import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ModalNuevaCategoria({ open, onClose, categoriasExistentes = [], onGuardarCategoria }) {
  const [nombreCategoria, setNombreCategoria] = useState("");
  const [tipoCategoria, setTipoCategoria] = useState("categoria");
  const [categoriaPadre, setCategoriaPadre] = useState("");

  // Aseguramos que categoriasExistentes sea un array válido
  const categoriasSeguras = categoriasExistentes || [];

  // Reinicia el formulario cuando se abre el modal
  useEffect(() => {
    if (open) {
      setNombreCategoria("");
      setTipoCategoria("categoria");
      setCategoriaPadre("");
    }
  }, [open]);

  // Maneja el guardado de la nueva categoría o subcategoría
  const handleGuardar = () => {
    if (!nombreCategoria.trim()) {
      alert("Debes ingresar un nombre para la categoría.");
      return;
    }

    if (tipoCategoria === "subcategoria" && !categoriaPadre) {
      alert("Debes seleccionar una categoría padre para la subcategoría.");
      return;
    }

    const nuevaCategoria = {
      nombre: nombreCategoria.trim(),
      tipo: tipoCategoria,
      parent: tipoCategoria === "subcategoria" ? categoriaPadre : null,
    };

    onGuardarCategoria(nuevaCategoria);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxHeight: 500, // Altura máxima del modal
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto", // Activa la barra de desplazamiento vertical
        }}
      >
        {/* Botón de cerrar */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Título */}
        <Typography variant="h6" gutterBottom>
          Añadir Nueva Categoría
        </Typography>

        {/* Campo para el nombre de la categoría */}
        <TextField
          fullWidth
          label="Nombre de la Categoría *"
          value={nombreCategoria}
          onChange={(e) => {
            const nuevoTexto = e.target.value.slice(0, 30);
            setNombreCategoria(nuevoTexto);
          }}
          margin="normal"
          required
          inputProps={{ maxLength: 30 }}
          helperText={`${30 - nombreCategoria.length} caracteres restantes`}
          autoComplete="off"
        />

        {/* Selector para elegir el tipo de categoría */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Clasificación</InputLabel>
          <Select
            value={tipoCategoria}
            onChange={(e) => setTipoCategoria(e.target.value)}
          >
            <MenuItem value="categoria">Categoría</MenuItem>
            <MenuItem value="subcategoria" disabled={categoriasSeguras.length === 0}>
              Subcategoría
            </MenuItem>
          </Select>
        </FormControl>

        {/* Selector para elegir la Categoría Padre (solo si es subcategoría) */}
        {tipoCategoria === "subcategoria" && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Categoría Padre *</InputLabel>
            <Select
              value={categoriaPadre}
              onChange={(e) => setCategoriaPadre(e.target.value)}
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: 300,
                    overflowY: "auto", // Barra de desplazamiento para el menú
                  },
                },
              }}
            >
              {categoriasSeguras
                .filter((cat) => !cat.categoriaPadre || cat.categoriaPadre === "")
                .map((cat, index) => (
                  <MenuItem key={index} value={cat.nombre}>
                    {cat.nombre}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}

        {/* Botón para guardar la nueva categoría */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleGuardar}
        >
          GUARDAR
        </Button>
      </Box>
    </Modal>
  );
}

export default ModalNuevaCategoria;
