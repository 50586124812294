import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase.js";

const Configuracion = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  // Estados para datos del usuario y configuración
  const [usuario, setUsuario] = useState(null);
  const [nombre, setNombre] = useState("");
  const [celular, setCelular] = useState("");
  const [dni, setDni] = useState("");
  const [preferencia, setPreferencia] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");

  // Estado para el modal de confirmación
  const [openModal, setOpenModal] = useState(false);
  // Estado para controlar el loading del botón "Guardar Cambios"
  const [loadingGuardar, setLoadingGuardar] = useState(false);

  // Verificar autenticación y cargar datos básicos desde Firestore
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setUsuario(firebaseUser);
        // Cargar información del usuario desde Firestore
        const userDocRef = doc(db, "usuarios", firebaseUser.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setNombre(data.nombre || firebaseUser.displayName || "");
          setCelular(data.celular || "");
          setDni(data.dni || "");
          setPreferencia(data.preferencia || "");
        } else {
          // Si no existe, asigna valores por defecto
          setNombre(firebaseUser.displayName || "");
          setCelular("");
          setDni("");
          setPreferencia("");
        }
      } else {
        navigate("/login");
      }
    });
    return unsubscribe;
  }, [auth, navigate]);

  // Función para guardar los cambios reales en Firestore
  const handleGuardarCambios = async () => {
    setError("");
    setMensaje("");
    setLoadingGuardar(true);
    if (!usuario) return;
    try {
      const userDocRef = doc(db, "usuarios", usuario.uid);
      await updateDoc(userDocRef, {
        nombre,
        celular,
        dni,
        preferencia
      });
      setMensaje("✅ Configuración actualizada correctamente.");
      setOpenModal(true);
    } catch (err) {
      console.error("Error al guardar cambios:", err);
      setError("❌ Error al guardar cambios. Inténtelo nuevamente.");
    } finally {
      setLoadingGuardar(false);
    }
  };

  // Función para cerrar sesión
  const handleCerrarSesion = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (err) {
      console.error("Error al cerrar sesión:", err);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={6} sx={{ p: 4, borderRadius: 3 }}>
          {/* Encabezado principal */}
          <Typography variant="h4" align="center" gutterBottom sx={{ color: "#1976D2", mb: 2 }}>
            Configuración
          </Typography>
          {/* Texto introductorio */}
          <Paper elevation={3} sx={{ p: 3, mb: 3, backgroundColor: "#E3F2FD", borderRadius: 2 }}>
            <Typography variant="h5" align="center" sx={{ color: "#0D47A1", fontWeight: "bold", mb: 1 }}>
              🔐 ¡Protege tu cuenta y accede sin preocupaciones! 🚀
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 1, color: "#424242" }}>
              Tener tus datos actualizados es clave para garantizar una experiencia segura y sin interrupciones.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, color: "#424242" }}>
              ✅ Recuperación de cuenta en caso de pérdida de contraseña o hackeo.<br />
              ✅ Verificación de identidad para evitar accesos no autorizados.<br />
              ✅ Soporte más rápido si necesitas ayuda con tu cuenta.<br />
              ✅ Acceso sin complicaciones a promociones y beneficios exclusivos.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1, color: "#424242" }}>
              📌 <strong>Manejo de datos:</strong><br />
              🔹 Tus datos están seguros con nosotros. Solo se usarán para estos fines y no se compartirán con terceros.<br />
              🔹 No recibirás spam ni publicidad no deseada.<br />
              🔹 Puedes actualizar tu información en cualquier momento.
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: "#0D47A1", fontWeight: "bold" }}>
              📞 ¿Problemas con tu cuenta? Escríbenos por WhatsApp al +51 906 115 152. ¡Guarda este número!
            </Typography>
            <Typography variant="body2" align="center" sx={{ color: "#0D47A1", fontWeight: "bold", mt: 1 }}>
              🔒 ¡Tu seguridad es nuestra prioridad!
            </Typography>
          </Paper>

          {/* Formulario de Configuración */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              label="Nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />

            <TextField
              fullWidth
              label="Número de Contacto"
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
            />

            <TextField
              fullWidth
              label="Número de DNI"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
            />

            <FormControl fullWidth>
              <InputLabel id="preferencia-label">Preferencia de Comunicación</InputLabel>
              <Select
                labelId="preferencia-label"
                label="Preferencia de Comunicación"
                value={preferencia}
                onChange={(e) => setPreferencia(e.target.value)}
              >
                <MenuItem value="Correo electrónico">Correo electrónico</MenuItem>
                <MenuItem value="Whatsapp">WhatsApp</MenuItem>
                <MenuItem value="Llamada">Llamada</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {mensaje && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {mensaje}
            </Alert>
          )}

          <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleGuardarCambios}
              disabled={loadingGuardar}
            >
              {loadingGuardar ? "Guardando..." : "Guardar Cambios"}
            </Button>
            <Button variant="outlined" color="error" fullWidth onClick={handleCerrarSesion}>
              Cerrar Sesión
            </Button>
          </Box>
        </Paper>
      </Container>

      {/* Modal de confirmación de guardado */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>¡Éxito!</DialogTitle>
        <DialogContent>
          <Typography>Se han guardado los cambios correctamente.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Configuracion;
