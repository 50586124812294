import React, { useState, useEffect } from "react";
import axios from "../api.js";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Paper,
  CircularProgress,
  Box
} from "@mui/material";
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from "firebase/auth"; // Importar Firebase Auth

function WizardForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    age: "",
    gender: "",
    occupation: "",
    discoverySource: [],
    financeExperience: "",
    platformGoals: [],
  });
  const [mensaje, setMensaje] = useState("");

  useEffect(() => {
    const auth = getAuth();

    // Observador de sesión (verifica si el usuario está autenticado)
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          console.log("Token obtenido en WizardForm:", token);
          const response = await axios.get("/api/wizard/estado", {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.data.completado) {
            navigate("/dashboard");
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error("Error al verificar el estado del wizard:", error);
          setLoading(false);
        }
      } else {
        console.error("❌ No hay usuario autenticado.");
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleLogin = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error);
    }
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="sm">
        <Paper elevation={3} sx={{ padding: 4, marginTop: 5, textAlign: "center" }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Cargando...
          </Typography>
        </Paper>
      </Container>
    );
  }

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked
        ? [...prevState[name], value]
        : prevState[name].filter((v) => v !== value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMensaje("");
    setSubmitting(true); // Activa el estado de cargando
    
    if (!formData.age || !formData.gender || !formData.occupation) {
      alert("Por favor, completa todos los campos obligatorios.");
      setSubmitting(false);
      return;
    }
  
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      console.log("👤 Usuario autenticado:", user);
      
      if (!user) {
        console.error("❌ No hay usuario autenticado.");
        setSubmitting(false);
        return;
      }
      
      const token = await user.getIdToken();
      console.log("🔑 Token obtenido:", token);
      
      if (!token) {
        console.error("❌ No se obtuvo un token. No se enviará la solicitud.");
        setSubmitting(false);
        return;
      }
      
      console.log("Enviando datos del wizard:", formData);
      await axios.post("https://lopsided-baby-production.up.railway.app/api/wizard/guardar", formData, {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      });
      
      
      console.log("✅ Datos enviados correctamente. Redirigiendo al Dashboard...");
      navigate("/dashboard");
    } catch (error) {
      console.error("❌ Error al guardar los datos:", error.response?.data || error);
      setMensaje(error.response?.data?.mensaje || "Error al guardar los datos.");
    } finally {
      setSubmitting(false);
    }
  };
  
  

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, marginTop: 5 }}>
        
        {/* 🔹 Logo arriba */}
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
          <img src="/logo.png" alt="Mi Plataforma Financiera" width={120} />
        </Box>

        <Typography variant="h5" gutterBottom>
          Completa tus datos para continuar
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Edad"
            name="age"
            type="number"
            value={formData.age}
            onChange={handleChange}
            margin="normal"
            required
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Sexo</FormLabel>
            <RadioGroup name="gender" value={formData.gender} onChange={handleChange}>
              <FormControlLabel value="Masculino" control={<Radio />} label="Masculino" />
              <FormControlLabel value="Femenino" control={<Radio />} label="Femenino" />
              <FormControlLabel value="Prefiero no decirlo" control={<Radio />} label="Prefiero no decirlo" />
            </RadioGroup>
          </FormControl>
          <TextField
            select
            fullWidth
            label="Ocupación"
            name="occupation"
            value={formData.occupation}
            onChange={handleChange}
            margin="normal"
            required
          >
            <MenuItem value="Empleado/a">Empleado/a</MenuItem>
            <MenuItem value="Empresario/a o dueño/a de negocio">Empresario/a o dueño/a de negocio</MenuItem>
            <MenuItem value="Freelancer / Autónomo">Freelancer / Autónomo</MenuItem>
            <MenuItem value="Estudiante">Estudiante</MenuItem>
            <MenuItem value="Otro">Otro</MenuItem>
          </TextField>

          {/* ¿Cómo se enteró de la plataforma? */}
<FormControl component="fieldset" margin="normal">
  <FormLabel component="legend">¿Cómo te enteraste de la plataforma?</FormLabel>
  <FormControlLabel 
    control={<Checkbox />} 
    label="Redes sociales" 
    name="discoverySource" 
    value="Redes sociales" 
    onChange={handleCheckboxChange} 
  />
  <FormControlLabel 
    control={<Checkbox />} 
    label="Google" 
    name="discoverySource" 
    value="Google" 
    onChange={handleCheckboxChange} 
  />
  <FormControlLabel 
    control={<Checkbox />} 
    label="Recomendación" 
    name="discoverySource" 
    value="Recomendación" 
    onChange={handleCheckboxChange} 
  />
</FormControl>

{/* Experiencia en Finanzas */}
<TextField
  select
  fullWidth
  label="¿Tienes experiencia previa en finanzas?"
  name="financeExperience"
  value={formData.financeExperience}
  onChange={handleChange}
  margin="normal"
  required
>
  <MenuItem value="Ninguna">Ninguna</MenuItem>
  <MenuItem value="Básica">Básica</MenuItem>
  <MenuItem value="Intermedia">Intermedia</MenuItem>
  <MenuItem value="Avanzada">Avanzada</MenuItem>
</TextField>

{/* Objetivo en la Plataforma */}
<FormControl component="fieldset" margin="normal">
  <FormLabel component="legend">¿Qué te gustaría hacer con la plataforma?</FormLabel>
  <FormControlLabel 
    control={<Checkbox />} 
    label="Llevar mis finanzas personales" 
    name="platformGoals" 
    value="Finanzas personales" 
    onChange={handleCheckboxChange} 
  />
  <FormControlLabel 
    control={<Checkbox />} 
    label="Controlar ingresos y egresos" 
    name="platformGoals" 
    value="Ingresos y egresos" 
    onChange={handleCheckboxChange} 
  />
  <FormControlLabel 
    control={<Checkbox />} 
    label="Finanzas de mi negocio" 
    name="platformGoals" 
    value="Finanzas de mi negocio" 
    onChange={handleCheckboxChange} 
  />
</FormControl>


<Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }} disabled={submitting}>
  {submitting ? "Guardando..." : "Guardar y continuar"}
</Button>

        </form>
      </Paper>
    </Container>
  );
}

export default WizardForm;
