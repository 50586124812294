import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { obtenerConceptos } from "../firebase/firebaseService.js";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { ButtonGroup } from "@mui/material";

function Reportes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { idTabla } = location.state || {};

  // Estados para el rango de fechas
  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");
  // Estado para el nivel de reporte seleccionado (inicializado en "1")
  const [nivelReporte, setNivelReporte] = useState("1");
  // Estado para mensajes de validación o aviso
  const [mensaje, setMensaje] = useState("");

  const [conceptos, setConceptos] = useState([]);
  

  useEffect(() => {
    if (idTabla) {
      obtenerConceptos(idTabla)
        .then((datos) => {
          setConceptos(datos);
        })
        .catch((error) => {
          console.error("Error al cargar conceptos:", error);
        });
    }
  }, [idTabla]);

    // Estado para almacenar los conceptos que caen en el rango de fechas seleccionado
    const [conceptosEnRango, setConceptosEnRango] = useState([]);

    useEffect(() => {
      // Filtramos los conceptos según el rango de fechas seleccionado
      const conceptosFiltrados = conceptos.filter((concepto) => {
        const fechaConcepto = new Date(concepto.fecha);
        const desde = fechaDesde ? new Date(fechaDesde) : null;
        const hasta = fechaHasta ? new Date(fechaHasta) : null;
        if (desde && fechaConcepto < desde) return false;
        if (hasta && fechaConcepto > hasta) return false;
        return true;
      });
      setConceptosEnRango(conceptosFiltrados);
    }, [fechaDesde, fechaHasta, conceptos]);

      // Definimos las condiciones para habilitar los botones de informes detallado y estado financiero
  const nivel1Disponible = true; // Siempre disponible
  const nivel2Disponible =
    conceptosEnRango.length > 0 &&
    conceptosEnRango.every((concepto) => concepto.nivel === "2" || concepto.nivel === "3");
  const nivel3Disponible =
    conceptosEnRango.length > 0 &&
    conceptosEnRango.every((concepto) => concepto.nivel === "3");

   // Función para verificar si todos los conceptos en el rango tienen nivel 2 o 3
const todosNivel2O3 = () => {
  if (conceptosEnRango.length === 0) return false;
  return conceptosEnRango.every((concepto) => concepto.nivel === "2" || concepto.nivel === "3");
};

// Función para verificar si todos los conceptos en el rango tienen nivel 3
const todosNivel3 = () => {
  if (conceptosEnRango.length === 0) return false;
  return conceptosEnRango.every((concepto) => concepto.nivel === "3");
};
 
  

  // Refs para los campos de fecha
  const fechaDesdeRef = useRef(null);
  const fechaHastaRef = useRef(null);

  // Función para seleccionar el nivel de reporte
  const seleccionarNivel = (nivel) => {
    setNivelReporte(nivel);
    setMensaje("");
  };

  

  // Función para generar el reporte y navegar a la página de reporte generado
  const generarReporte = () => {
    if (!fechaDesde || !fechaHasta) {
      setMensaje("Debes seleccionar un rango de fechas.");
      return;
    }
    if (!nivelReporte) {
      setMensaje("Debes seleccionar un nivel de reporte.");
      return;
    }
    localStorage.setItem("fechaDesdeReporte", fechaDesde);
    localStorage.setItem("fechaHastaReporte", fechaHasta);
    navigate("/reporte-generado", {
      state: { 
        fechaDesde, 
        fechaHasta, 
        nivelReporte,
        nombreTabla: idTabla  // Pasa el identificador real de la tabla
      }
    });
  };
  
  
  

  return (
    <Container sx={{ mt: 4, pb: 5 }}>
      <Paper sx={{ p: 3, mb: 3, borderRadius: 2, boxShadow: 3 }}>
  <Typography variant="h4" gutterBottom sx={{ color: "#1976d2", textAlign: "center" }}>
    Generador de Reportes Financieros
  </Typography>
  <Typography variant="body1" gutterBottom sx={{ mb: 2, color: "#424242", textAlign: "center" }}>
    Selecciona el rango de fechas y el nivel de detalle para generar tu informe.
  </Typography>

  <Grid container spacing={2} alignItems="center">
    {/* Campo Fecha Desde */}
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        label="Fecha Desde"
        type="date"
        value={fechaDesde}
        onChange={(e) => setFechaDesde(e.target.value)}
        margin="normal"
        InputLabelProps={{ shrink: true }}
        sx={{ cursor: "pointer" }}
      />
    </Grid>
    {/* Campo Fecha Hasta */}
    <Grid item xs={12} sm={6}>
      <TextField
        fullWidth
        label="Fecha Hasta"
        type="date"
        value={fechaHasta}
        onChange={(e) => setFechaHasta(e.target.value)}
        margin="normal"
        InputLabelProps={{ shrink: true }}
        sx={{ cursor: "pointer" }}
      />
    </Grid>

    {/* Selección de Nivel de Reporte con Botones */}
    <Grid item xs={12}>
      <Box sx={{ mt: 2, textAlign: "center" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
          Selecciona el Nivel de Reporte:
        </Typography>
        <ButtonGroup variant="contained" aria-label="nivel de reporte">
          <Button
            onClick={() => setNivelReporte("1")}
            color={nivelReporte === "1" ? "primary" : "inherit"}
          >
            Nivel 1 - Informe Básico
          </Button>
          <Button
            onClick={() => setNivelReporte("2")}
            disabled={!nivel2Disponible}
            color={nivelReporte === "2" ? "success" : "inherit"}
          >
            Nivel 2 - Informe Detallado
          </Button>
          <Button
            onClick={() => setNivelReporte("3")}
            disabled={!nivel3Disponible}
            color={nivelReporte === "3" ? "error" : "inherit"}
          >
            Nivel 3 - Estado de Resultados
          </Button>
        </ButtonGroup>
      </Box>
    </Grid>
  </Grid>

  {mensaje && (
    <Alert severity="warning" sx={{ mt: 2 }}>
      {mensaje}
    </Alert>
  )}

    <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
    <Button variant="contained" color="primary" onClick={generarReporte} sx={{ mr: 2 }}>
      GENERAR REPORTE
    </Button>
    <Button variant="outlined" onClick={() => navigate(-1)}>
      Volver
    </Button>
  </Box>

  <Box sx={{ mt: 3, p: 2, bgcolor: "#e3f2fd", borderRadius: 1, border: "1px solid #ddd" }}>
    <Typography variant="body2" color="textSecondary" align="center">
      ¿Algún botón de informe aparece deshabilitado? Esto ocurre porque para acceder al informe detallado es necesario que todos los conceptos del período seleccionado estén correctamente completados.
      <br /><br />
      <strong>Para el Informe Detallado (Nivel 2):</strong> Todos los conceptos deben tener categoría (Nivel 2 o superior).
      <br />
      <strong>Para el Estado de Resultados (Nivel 3):</strong> Todos los conceptos deben estar en Nivel 3.
      <br /><br />
      Si encuentras algún concepto incompleto o con datos faltantes, revísalo en tu Tabla Financiera y asegúrate de completarlo.
      <br /><br />
      ¿Tienes dudas? Visita nuestro <a
        href="https://www.tusitio.com/tutoriales"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#1976d2", textDecoration: "underline" }}
      >
        vídeo tutorial
      </a> para más ayuda.
    </Typography>
  </Box>
</Paper>


    </Container>
  );
}

export default Reportes;


