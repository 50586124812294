import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Paper,
  Box
} from "@mui/material";

const tutoriales = [
  {
    id: 1,
    titulo: "Tutorial de Introducción de Finanzas Fáciles",
    descripcion: "Conoce los fundamentos de nuestra plataforma paso a paso. Aprende a navegar y aprovechar todas las funcionalidades con ejemplos prácticos. Empieza el camino hacia tu libertad financiera.",
    imagen: process.env.PUBLIC_URL + "/images/tutorial1.png",
    link: "https://www.youtube.com/watch?v=bb5mBygaIME"
  },
  {
    id: 2,
    titulo: "Registra tus Finanzas de Forma Básica y Eficaz",
    descripcion: "Ingresa concepto, monto, fecha y marca si es ingreso o egreso. Perfecto para eventos y necesidades puntuales, además puedes adjuntar comprobantes y generar reportes automáticos en PDF.",
    imagen: process.env.PUBLIC_URL + "/images/tutorial2.png",
    link: "https://youtu.be/EyQpbUH2tIk"
  },
  {
    id: 3,
    titulo: "Personaliza y Visualiza tus Finanzas a Detalle",
    descripcion: "Podrás crear categorías y subcategorías, visualizar gráficos interactivos y conocer datos clave como promedio de gasto diario y la categoría de mayor inversión. ¡Lleva el control total de tus finanzas!",
    imagen: process.env.PUBLIC_URL + "/images/tutorial3.png",
    link: "https://youtu.be/gsAtsG_ejfs"
  },
  {
    id: 4,
    titulo: "Lleva tu Negocio al Siguiente Nivel",
    descripcion: "La herramienta profesional para clasificar ingresos, costos y gastos operativos. Genera estados de resultados precisos y toma decisiones estratégicas para hacer crecer tu negocio. ¡Potencia tu empresa hoy!",
    imagen: process.env.PUBLIC_URL + "/images/tutorial4.png",
    link: "https://youtu.be/P4-ojqDPsTs"
  },
  {
    id: 5,
    titulo: "Amplía Tu Acceso a la plataforma por 1 año",
    descripcion: "Descubre cómo empezar con 7 días de prueba sin tarjeta y luego suscríbete anualmente por tan solo 35 soles (YAPE en Perú) o 11$ (mediante mercadopago) desde el exterior. ¡Gestiona tus finanzas al mejor precio!",
    imagen: process.env.PUBLIC_URL + "/images/tutorial5.png",
    link: "https://youtu.be/3UMs-FYof_Y"
  },
  {
    id: 6,
    titulo: "Gana 6 Meses Gratis en Finanzas Fáciles",
    descripcion: "Aprende cómo obtener hasta 6 meses gratis en Finanzas Fáciles cumpliendo retos en redes sociales. Comparte tu experiencia en Twitter, Instagram, Facebook o TikTok y disfruta de beneficios exclusivos. ¡Participa y gana!",
    imagen: process.env.PUBLIC_URL + "/images/tutorial6.png",
    link: "https://youtu.be/5RM5h2gjpaw"
  },
  {
    id: 7,
    titulo: "TUTORIAL COMPLETO",
    descripcion: "¿Te quedan dudas? En este vídeo se toma paso a paso y con paciencia, todas las funcionalidades de la plataforma.",
    imagen: process.env.PUBLIC_URL + "/images/tutorial7.png",
    link: "https://youtu.be/Up3_9kdKwPE"
  },
];

const Tutoriales = () => {
  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Paper
        elevation={6}
        sx={{
          p: 4,
          borderRadius: 3,
          backgroundColor: "#f9f9f9"
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: "#0D47A1", fontWeight: "bold" }}
        >
          Tutoriales
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 3, color: "#424242" }}>
          Explora nuestros tutoriales para aprender a aprovechar al máximo nuestra plataforma.
        </Typography>
        <Grid container spacing={3}>
          {tutoriales.map((tutorial) => (
            <Grid item xs={12} sm={6} md={4} key={tutorial.id}>
              <Card
                sx={{
                  minHeight: 300,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <CardActionArea
                  onClick={() => window.open(tutorial.link, "_blank")}
                  sx={{ flex: 1 }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={tutorial.imagen}
                    alt={tutorial.titulo}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{ color: "#0D47A1", fontWeight: "bold" }}
                    >
                      {tutorial.titulo}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {tutorial.descripcion}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default Tutoriales;
