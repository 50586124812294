import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ModalNuevaCategoria from "./ModalNuevaCategoria";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { obtenerCategorias } from "../firebase/firebaseService.js"; // Asegúrate de importar tu función de Firestore
import { guardarCategoria } from "../firebase/firebaseService.js"; // Asegúrate de importar la función

function ModalNuevoConcepto({
  open,
  onClose,
  onGuardarConcepto,
  categorias,
  setCategorias,
  nombreTabla,
  conceptoAEditar,
  monedaSeleccionada
}) {
  // Estados para campos básicos y para el formulario general
  const [concepto, setConcepto] = useState("");
  const [monto, setMonto] = useState("");
  const [fecha, setFecha] = useState("");
  const [tipo, setTipo] = useState("");
  const [formValues, setFormValues] = useState({
    fecha: "",
    concepto: "",
    monto: "",
    tipo: "",
    categoria: "",
    moneda: "",
    imagen: "", // Aquí se guardará el Data URL de la imagen (si se usa un input individual)
    nivel: "1",
  });
  // Estado para manejar imágenes subidas (arreglo de archivos o URLs)
  const [imagenes, setImagenes] = useState([]);

  // Otros estados
  const [nivel2Activo, setNivel2Activo] = useState(false);
  const [nivel3Activo, setNivel3Activo] = useState(false);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");
  const [clasificacion, setClasificacion] = useState("");
  const [modalCategoriaOpen, setModalCategoriaOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const nivel1Completo = concepto.trim() && monto.trim() && fecha.trim() && tipo;
  const fechaRef = React.useRef(null);

  // Función para manejar el cambio en el campo "Monto"
  const handleMontoChange = (e) => {
    let valor = e.target.value.replace(/\D/g, "").slice(0, 12); // Limitar a 12 dígitos
    if (!valor) {
      setMonto(""); // Si el usuario borra todo, limpiamos el campo
      return;
    }
    // Convertir el valor a número flotante con 2 decimales
    let numero = (parseFloat(valor) / 100).toFixed(2);
    // Formatear con separadores de miles
    let montoFormateado = new Intl.NumberFormat("es-PE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numero);
    setMonto(montoFormateado);
  };

  // Función para manejar la carga de imágenes (para previsualización)
  const handleImagenesChange = (event) => {
    const files = Array.from(event.target.files);
    if (imagenes.length + files.length > 3) {
      alert("Solo puedes subir un máximo de 3 imágenes.");
    } else {
      // Convertir cada archivo a Data URL y luego actualizar el estado "imagenes"
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          // Cuando la conversión termina, se agrega la Data URL al estado
          setImagenes((prev) => [...prev, reader.result]);
        };
        reader.readAsDataURL(file);
      });
    }
  };
  

  // useEffect para inicializar el formulario al abrir el modal
  useEffect(() => {
    if (open) {
      console.log("📌 🔍 ModalNuevoConcepto abierto, inicializando formulario.");
  
      if (conceptoAEditar) {
        setConcepto(conceptoAEditar.concepto || "");
        setMonto(
          conceptoAEditar.monto
            ? parseFloat(conceptoAEditar.monto).toFixed(2)
            : ""
        );
        setFecha(conceptoAEditar.fecha || "");
        setTipo(conceptoAEditar.tipo || "");
        // Solo establecemos las imágenes si el estado actual está vacío,
        // para no sobrescribir cambios en modo edición.
        setImagenes((prevImagenes) =>
          prevImagenes.length === 0 ? (conceptoAEditar.imagenes || []) : prevImagenes
        );
        setCategoriaSeleccionada(conceptoAEditar.categoria || "");
        setClasificacion(conceptoAEditar.clasificacion || "");
        setNivel2Activo(false);
        setNivel3Activo(false);
      } else {
        setConcepto("");
        setMonto("");
        setFecha("");
        setTipo("");
        setImagenes([]);
        setCategoriaSeleccionada("");
        setClasificacion("");
        setNivel2Activo(false);
        setNivel3Activo(false);
      }
    }
  }, [open, nombreTabla]); // Eliminamos conceptoAEditar y setCategorias de las dependencias
  

  useEffect(() => {
    const cargarCategorias = async () => {
      if (nombreTabla) {
        try {
          const categoriasDB = await obtenerCategorias(nombreTabla);
          setCategorias(categoriasDB);
        } catch (error) {
          console.error("❌ Error al obtener categorías desde Firestore:", error);
          setCategorias([]);
        }
      }
    };
  
    if (open) {
      cargarCategorias();
    }
  }, [open, nombreTabla, setCategorias]);

  const validarNivel1 = () => {
    const completo = concepto.trim() && monto.trim() && fecha.trim() && tipo;
    if (!completo) {
      setNivel3Activo(false); // Solo desactiva Nivel 3 si el Nivel 1 no está completo
    }
  };

  const validarNivel2 = () => {
    if (categoriaSeleccionada.trim()) {
      setNivel3Activo((prev) => prev || false);
    } else {
      setNivel3Activo(false);
    }
  };

  const handleGuardarCategoria = async (nuevaCategoria) => {
    try {
      const nuevasCategorias = [...categorias, nuevaCategoria];
  
      if (nombreTabla) {
        await guardarCategoria(nombreTabla, nuevasCategorias); // ⬅️ Ahora guarda en Firestore
        console.log("✅ Nueva categoría guardada en Firestore:", nuevasCategorias);
      }
  
      setCategorias(nuevasCategorias);
      setCategoriaSeleccionada(nuevaCategoria.nombre);
      setNivel2Activo((prev) => prev || true);
  
      if (modalCategoriaOpen) {
        setModalCategoriaOpen(false);
      }
    } catch (error) {
      console.error("❌ Error al guardar categoría en Firestore:", error);
    }
  };

  const handleGuardar = async () => {
    setSaving(true);
    if (!concepto || !monto || !fecha || !tipo) {
      alert("Debes completar todos los campos del Nivel 1 antes de continuar.");
      setSaving(false);
      return;
    }
    // Convertir el monto a número eliminando comas
    const montoNumerico = parseFloat(monto.replace(/,/g, ""));
    if (isNaN(montoNumerico)) {
      alert("El monto ingresado no es válido.");
      setSaving(false);
      return;
    }
    const nivelGuardado = nivel3Activo ? "3" : nivel2Activo ? "2" : "1";
  
    const nuevoConcepto = {
      concepto,
      monto: montoNumerico.toFixed(2),
      fecha,
      tipo,
      imagenes,
      categoria: categoriaSeleccionada || "Sin categoría",
      clasificacion: clasificacion || "Sin clasificación",
      moneda: conceptoAEditar ? conceptoAEditar.moneda : monedaSeleccionada,
      nivel: nivelGuardado
    };
  
    try {
      await onGuardarConcepto(nuevoConcepto);
      // Cerrar el modal y restablecer el formulario
      setNivel2Activo(false);
      setNivel3Activo(false);
      setTimeout(onClose, 0);
      setConcepto("");
      setMonto("");
      setFecha("");
      setTipo("");
      setImagenes([]);
      setCategoriaSeleccionada("");
      setClasificacion("");
      setNivel2Activo(false);
      setNivel3Activo(false);
    } catch (error) {
      console.error("❌ Error al guardar el concepto en Firestore:", error);
    } finally {
      setSaving(false);
    }
  };
  

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 450,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" gutterBottom>
          Agregar Nuevo Concepto
        </Typography>

        {/* Nivel 1 */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "green" }}>
          ✅ NIVEL 1: Registro Básico
        </Typography>
        <TextField
          fullWidth
          label="Concepto *"
          value={concepto}
          onChange={(e) => {
            const nuevoTexto = e.target.value.slice(0, 150);
            setConcepto(nuevoTexto);
            validarNivel1();
          }}
          margin="normal"
          required
          onBlur={validarNivel1}
          inputProps={{ maxLength: 150 }}
          helperText={`${150 - concepto.length} caracteres restantes`}
          autoComplete="off"
        />

        <TextField
          fullWidth
          label="Monto *"
          value={monto}
          onChange={handleMontoChange} // Aplica el formato en tiempo real
          margin="normal"
          required
          autoComplete="off"
        />

        <Box
          sx={{
            position: "relative",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => {
            if (fechaRef.current?.showPicker) {
              fechaRef.current.showPicker();
            }
          }}
        >
          <TextField
            inputRef={fechaRef}
            fullWidth
            label="Fecha *"
            type="date"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
            margin="normal"
            required
            InputLabelProps={{ shrink: true }}
            onBlur={validarNivel1}
            autoComplete="off"
            sx={{
              "& input": {
                cursor: "pointer",
              },
            }}
          />
        </Box>

        <FormControl fullWidth margin="normal">
          <InputLabel>Tipo *</InputLabel>
          <Select
            value={tipo}
            onChange={(e) => {
              setTipo(e.target.value);
              validarNivel1();
            }}
            required
            onBlur={validarNivel1}
          >
            <MenuItem value="Ingreso" sx={{ color: "green" }}>
              Ingreso
            </MenuItem>
            <MenuItem value="Egreso" sx={{ color: "red" }}>
              Egreso
            </MenuItem>
          </Select>
        </FormControl>

        {/* Previsualización de imágenes */}
        <Typography variant="body2" sx={{ mt: 2 }}>
          Adjuntar Imágenes (Opcional, máx. 3)
        </Typography>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleImagenesChange}
          disabled={imagenes.length >= 3}
        />

        <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
          {imagenes.map((img, index) => {
            let src = "";
            if (typeof img === "string") {
              src = img;
            } else if (img instanceof Blob) {
              src = URL.createObjectURL(img);
            }
            return (
              <Box key={index} sx={{ position: "relative", display: "inline-block" }}>
                {src && (
                  <img
                    src={src}
                    alt="preview"
                    width={70}
                    height={70}
                    style={{ borderRadius: 4 }}
                  />
                )}
                <IconButton
                  size="small"
                  onClick={() => {
                    setImagenes((prev) => prev.filter((_, i) => i !== index));
                  }}
                  sx={{
                    position: "absolute",
                    top: -8,
                    right: -8,
                    backgroundColor: "white",
                    padding: "2px",
                    "&:hover": { backgroundColor: "lightgray" },
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            );
          })}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Nivel 2 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: nivel1Completo ? "pointer" : "not-allowed",
          }}
          onClick={() => {
            if (nivel1Completo) {
              setNivel2Activo((prev) => (prev === false ? true : prev));
            }
          }}
        >
          {nivel1Completo ? (
            nivel2Activo ? (
              <LockOpenIcon sx={{ color: "orange", mr: 1 }} />
            ) : (
              <LockIcon sx={{ color: "orange", mr: 1 }} />
            )
          ) : (
            <LockIcon sx={{ color: "gray", mr: 1 }} />
          )}
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              color:
                concepto.trim() && monto.trim() && fecha.trim() && tipo
                  ? "orange"
                  : "gray",
            }}
          >
            NIVEL 2: Categorización de Conceptos
          </Typography>
        </Box>

        {nivel2Activo && (
          <>
           <Typography variant="caption" color="textSecondary">
  Para agregar una nueva categoría, utilice el botón "Categorías" en la página de la Tabla Financiera.
</Typography>

            <FormControl fullWidth margin="normal">
              <InputLabel>Categoría</InputLabel>
              <Select
                value={categoriaSeleccionada}
                onChange={(e) => {
                  const valor = e.target.value;
                  console.log("Categoría seleccionada:", valor);
                  setCategoriaSeleccionada(valor);
                  validarNivel2();
                }}
              >
                <MenuItem value="" disabled>
                  Selecciona categoría
                </MenuItem>
                {categorias && categorias.length > 0
                  ? categorias.map((categoria, index) => (
                      <MenuItem
                        key={index}
                        value={categoria.nombre}
                        sx={{
                          color: categoria.tipo === "categoria" ? "red" : "black",
                        }}
                      >
                        {categoria.nombre}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </>
        )}

        <Divider sx={{ my: 2 }} />

        {/* Nivel 3 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: (categoriaSeleccionada || "").trim() ? "pointer" : "not-allowed",
          }}
          onClick={() => {
            if ((categoriaSeleccionada || "").trim()) {
              setNivel3Activo((prev) => !prev);
            }
          }}
        >
{(categoriaSeleccionada || "").trim() ? (
            nivel3Activo ? (
              <LockOpenIcon sx={{ color: "red", mr: 1 }} />
            ) : (
              <LockIcon sx={{ color: "red", mr: 1 }} />
            )
          ) : (
            <LockIcon sx={{ color: "gray", mr: 1 }} />
          )}
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              color: (categoriaSeleccionada || "").trim() ? "red" : "gray",
            }}
          >
            NIVEL 3: Estado de Resultados
          </Typography>
        </Box>

        {nivel3Activo && (
          <FormControl component="fieldset" sx={{ mt: 2 }}>
            <RadioGroup value={clasificacion} onChange={(e) => setClasificacion(e.target.value)}>
              <FormControlLabel value="Ingresos" control={<Radio />} label="Ingresos" />
              <FormControlLabel value="Otros Ingresos" control={<Radio />} label="Otros Ingresos" />
              <FormControlLabel
                value="Costo de Ventas (COGS)"
                control={<Radio />}
                label="Costo de Ventas (COGS)"
              />
              <FormControlLabel value="Gasto Operativo" control={<Radio />} label="Gasto Operativo" />
            </RadioGroup>
          </FormControl>
        )}

<Button
  variant="contained"
  color="primary"
  fullWidth
  sx={{ mt: 3 }}
  onClick={handleGuardar}
  disabled={saving}
>
  {saving ? "Guardando..." : "GUARDAR"}
</Button>


        <ModalNuevaCategoria
          open={modalCategoriaOpen}
          onClose={() => setModalCategoriaOpen(false)}
          onGuardarCategoria={handleGuardarCategoria}
          categoriasExistentes={categorias}
        />
      </Box>
    </Modal>
  );
}

export default ModalNuevoConcepto;
